import React, { useRef } from "react";
import { getDate, isToday } from "date-fns";
import {
  DatePicker,
  DatePickerInput,
  DatePickerCalendar,
  DatePickerMonth,
  DatePickerButton,
  DatePickerTable,
} from "@reecelucas/react-datepicker";

export default function DateInput({
  disabled,
  placeholder,
  required,
  name,
  endDate,
  startDate,
  excludedDates,
  ...inputProps
}) {
  const inputRef = useRef();
  const renderScreenReaderMsg = () => (
    <>
      <p>
        Press the down arrow key to interact with the calendar and select a
        date. The following keyboard shortcuts can be used to change dates.
      </p>
      <ul>
        <li>Enter Key: select the date in focus.</li>
        <li>
          Right and left arrow keys: Move backward (left) and forward (right) by
          one day.
        </li>
        <li>
          Up and down arrow keys: Move backward (up) and forward (down) by one
          week.
        </li>
        <li>Page up and page down keys: Switch months.</li>
        <li>Home and end keys: go to the first or last day of a week.</li>
        <li>Escape key: Return to the date input field.</li>
      </ul>
    </>
  );

  return (
    <DatePicker
      onSelect={(date) => date}
      minDate={startDate}
      maxDate={endDate}
      excludeDates={excludedDates}
      className="datePicker"
      shouldCloseOnSelect={false}
    >
      <DatePickerInput
        className="m-form__input"
        dateFormat={"MM/dd/yyyy"}
        screenReaderMessage={renderScreenReaderMsg}
        name={name}
        id={name}
      />
      <DatePickerCalendar className="datePicker__wrapper">
        <div className="datePicker__navigation">
          <DatePickerButton
            updateMonth={({ prev }) => prev()}
            className="datePicker__navButton datePicker__navButton--prev"
            aria-label="previous month"
          />
          <DatePickerMonth className="datePicker__caption" />
          <DatePickerButton
            updateMonth={({ next }) => next()}
            className="datePicker__navButton datePicker__navButton--next"
            aria-label="next month"
          />
        </div>
        <DatePickerTable
          className="datePicker__table"
          renderDayContent={(date) => (
            <div
              className="datePicker__tableDay"
              data-istoday={isToday(date) ? "true" : "false"}
            >
              {getDate(date)}
            </div>
          )}
        />
      </DatePickerCalendar>
    </DatePicker>
  );
}
