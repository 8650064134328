import React, { PureComponent } from "react";
import classNames from "classnames";
import VideoPlayer from "../Wrapper";
import types from "api/types";
import Text from "../Text";

export default class FeaturedVideo extends PureComponent {
  static displayName = "Content.VideoPlayer.Featured";

  static propTypes = {
    video: types.videoType,
  };

  render() {
    const { video } = this.props;
    if (!video) {
      return null;
    }
    const {
      youtube_id: youtubeId,
      video_image: videoImage,
      title,
      description,
    } = video;
    const noDescription = !description;

    return (
      <div
        className={classNames("featured-video", "video-teaser", {
          "featured-video--vertical": noDescription,
        })}
      >
        <VideoPlayer videoId={youtubeId}>
          <figure
            style={{
              backgroundImage: `url('${videoImage}')`,
            }}
          />
        </VideoPlayer>
        <Text
          parentClassName="featured-video"
          description={description}
          title={title}
        />
      </div>
    );
  }
}
