import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Slideshow from "../Slideshow";
import { connect } from "react-redux";
import { orderBy } from "lodash";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import ScrollLock from "cic-scroll-lock";

class ImageGridSlideshow extends PureComponent {
  static displayName = "Content.ImageGridSlideshow";

  static propTypes = {
    slides: PropTypes.array,
  };

  static mapStateToProps = (state, ownProps) => {
    return state.slideshow;
  };

  constructor(props) {
    super();
    this.slideshow = React.createRef();
    this.scrollLock = new ScrollLock();

    this.state = {
      active: 0,
    };
  }

  componentDidMount() {
    const sortedProps = Object.assign({}, this.props);

    // sort images in global state by their Y-position on page
    const sortedImages = orderBy(sortedProps.imageOffsets, "YOffset");

    // now reorder slides to match this order
    const sortedSlides = sortedProps.slides
      .map((image) => {
        const n = sortedImages.map((e) => e.id).indexOf(image.id);
        return [n, image];
      })
      .sort()
      .map((j) => j[1]);

    sortedProps.slides = sortedSlides;

    this.sortedProps = sortedProps;
  }

  componentDidUpdate(prevProps) {
    // If slideshow is being opened, set active state to
    // index of clicked image in sortedProps.slides array
    const slideShowEl = ReactDOM.findDOMNode(this.slideshow.current);

    const { slides } = this.sortedProps;
    const index = slides.findIndex((slide) => slide.id === this.props.current);
    const active = Math.max(0, index);

    if (this.props.open && this.props.open !== prevProps.open) {
      this.setState({
        active,
      });
    }

    if (this.props.open) {
      this.scrollLock.only(slideShowEl);
      document.body.classList.add("no-scroll");
    } else {
      this.scrollLock.any(slideShowEl);
      document.body.classList.remove("no-scroll");
    }
  }

  closeSlideshow = () => {
    this.props.dispatch({
      type: "CLOSE_SLIDESHOW",
    });
  };

  setActive = (active) => {
    this.setState({ active });
  };

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="fade"
        transitionEnterTimeout={100}
        transitionLeaveTimeout={350}
      >
        {this.props.open && (
          <Slideshow
            slides={this.sortedProps.slides}
            contentType="artwork"
            active={this.state.active}
            onUpdate={this.setActive}
            onClose={this.closeSlideshow}
            ref={this.slideshow}
          />
        )}
      </ReactCSSTransitionGroup>
    );
  }
}

export default connect(ImageGridSlideshow.mapStateToProps)(ImageGridSlideshow);
