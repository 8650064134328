import React, { useState } from "react";
import { Radio, OtherOption } from "../atomic";
import classNames from "classnames";

export default function RadioGroup({
  name,
  required,
  options = [],
  description,
  other,
  label,
  ...inputProps
}) {
  const [showOther, setShowOther] = useState(false);

  const otherOption = options.find((o) => o.value === "Other");
  const filteredOptions = otherOption
    ? options.filter((o) => o.value !== otherOption.value)
    : options;

  return (
    <div role="radiogroup" required={required} className="m-form__field">
      <label
        className={classNames("m-form__label", {
          "m-form__required": required,
        })}
      >
        {label}
      </label>
      {description && (
        <p
          className="m-form__field-descript"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {filteredOptions.map(({ value, label, id }) => (
        <Radio
          name={name}
          key={value}
          label={label}
          value={value}
          required={required}
          id={id}
          {...inputProps}
        />
      ))}
      {other && (
        <Radio
          name={name}
          label={otherOption?.label}
          value={otherOption?.value}
          onInput={() => setShowOther(true)}
        />
      )}
      {showOther && <OtherOption wrapperType="radio" {...other} />}
    </div>
  );
}
