import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import DateNav from "./DateNav";
import Filters from "./Filters";

export default class CalendarNav extends PureComponent {
  static displayName = "Calendar.Nav";

  static propTypes = {
    handleDayClick: PropTypes.func,
    selectedDay: PropTypes.object,
    filters: PropTypes.array,
    selectedFilters: PropTypes.array,
    handleFilterClick: PropTypes.func,
    handleFilterClear: PropTypes.func,
    canAccessDate: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      visibility: {
        date: false,
        filters: false,
      },
      dateHeight: 0,
      filterHeight: 0,
      totalHeight: 82, // height of .utility, doesn't change
    };
  }

  toggleDateNavMobile = () => {
    const { visibility, dateHeight } = this.state;
    this.setState({
      visibility: {
        date: !visibility.date,
        filters: false,
      },
      totalHeight: visibility.date ? 82 : 82 + dateHeight,
    });
  };

  toggleFiltersMobile = () => {
    const { visibility, filterHeight } = this.state;
    this.setState({
      visibility: {
        date: false,
        filters: !visibility.filters,
      },
      totalHeight: visibility.filters ? 82 : 82 + filterHeight,
    });
  };

  render() {
    const { visibility, dateHeight, filterHeight, totalHeight } = this.state;
    const containerClasses = classNames("filters", {
      navVisible: visibility.date || visibility.filters,
      navHidden: !visibility.date && !visibility.filters,
    });
    const containerStyles = {
      height: totalHeight,
    };

    return (
      <div className={containerClasses} style={containerStyles}>
        <div className="utility" aria-hidden="true">
          <button className="select-date" onClick={this.toggleDateNavMobile}>
            {"Select a date"}
          </button>
          <button
            className="button-tertiary plus"
            onClick={this.toggleFiltersMobile}
          >
            {"Filter"}
          </button>
        </div>
        <DateNav
          handleDayClick={this.props.handleDayClick}
          canAccessDate={this.props.canAccessDate}
          selectedDay={this.props.selectedDay}
          visibility={this.state.visibility.date}
          getHeight={(dateHeight) => this.setState({ dateHeight })}
        />
        <Filters
          items={this.props.filters}
          selected={this.props.selectedFilters}
          onFilterClick={this.props.handleFilterClick}
          onFilterClear={this.props.handleFilterClear}
          visibility={this.state.visibility.filters}
          getHeight={(filterHeight) => this.setState({ filterHeight })}
        />
      </div>
    );
  }
}
