import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class SlideshowDetailSlide extends PureComponent {
  static displayName = "Content.Slideshow.DetailSlide";

  static propTypes = {
    data: PropTypes.object,
  };

  renderArtworkHeading(slide) {
    let title;

    if (slide.formattedTitle) {
      title = (
        <span dangerouslySetInnerHTML={{ __html: slide.formattedTitle }}></span>
      );
    } else {
      title = slide.title;
    }

    return (
      <h1 className="heading-secondary">
        {title}
        {slide.year ? ", " + slide.year : ""}
      </h1>
    );
  }

  renderArtworkNotes(notes, copyright) {
    return (
      <div className="meta-item">
        <h3>Credit</h3>
        {notes && <p>{notes}</p>}
        {copyright && <p>{copyright}</p>}
      </div>
    );
  }

  renderArtworkRelated(tags) {
    return (
      <div className="meta-item">
        <h3>See Also</h3>
        <ul className="related">
          {tags.map((tag, index) => {
            return (
              <li key={index}>
                <a href={tag.url}>{tag.text}</a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderArtistMeta(artist) {
    const { displayName, nationality } = artist;
    let lifespan;

    if (artist.birthYear && artist.deathYear) {
      lifespan = `${artist.birthYear}-${artist.deathYear}`;
    } else if (artist.birthYear) {
      lifespan = `born ${artist.birthYear}`;
    } else {
      lifespan = "";
    }

    return (
      <div>
        <p>
          {displayName} ({nationality}
          {nationality && lifespan ? ", " : ""}
          {lifespan || ""})
        </p>
      </div>
    );
  }

  renderArtistInfo(info) {
    return (
      <div>
        <p dangerouslySetInnerHTML={{ __html: info }} />
      </div>
    );
  }

  renderArtworkMeta(slide) {
    const {
      artist,
      artistInfo,
      medium,
      dimensions,
      accessionNumber,
      copyright,
      notes,
      tags,
    } = slide;

    const itemClass = "meta-item";

    return (
      <div className="column-wrapper">
        {(artist || artistInfo) && (
          <div className={itemClass}>
            <h3>Artist</h3>
            {artist && this.renderArtistMeta(artist)}
            {artistInfo && this.renderArtistInfo(artistInfo)}
          </div>
        )}
        {medium && (
          <div className={itemClass}>
            <h3>Medium</h3>
            <p dangerouslySetInnerHTML={{ __html: medium }} />
          </div>
        )}
        {dimensions && (
          <div className={itemClass}>
            <h3>Dimensions</h3>
            <p dangerouslySetInnerHTML={{ __html: dimensions }} />
          </div>
        )}
        {accessionNumber && (
          <div className={itemClass}>
            <h3>Accession Number</h3>
            <p>{accessionNumber}</p>
          </div>
        )}
        {notes || copyright ? this.renderArtworkNotes(notes, copyright) : null}
        {tags.length > 0 ? this.renderArtworkRelated(tags) : null}
      </div>
    );
  }

  render() {
    const slide = this.props.data;

    return (
      <div>
        <section className="detail-header flex-wrapper">
          <header className="container-focus">
            {this.renderArtworkHeading(slide)}
          </header>
        </section>
        <section className="container-primary content-section bg-white">
          <div className="container-wide content-default">
            {this.renderArtworkMeta(slide)}
          </div>
        </section>
      </div>
    );
  }
}
