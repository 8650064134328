import Sb from "../abstract/StatefulBehavior";
import Percolator from "../../helpers/Percolator";
import SlideCards from "./SlideCards";

export default class SlideGalleryImages extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.state = {
      active: null,
    };

    this.update();
    this.initSlider();
    this.bindSlideEvents();
  }

  get slider() {
    return this.refs.slides;
  }

  get slides() {
    return this.refs.slide;
  }

  get captionWrapper() {
    return this.refs.captionWrapper;
  }

  get captions() {
    return this.refs.caption;
  }

  update = () => {
    this.captions.forEach((caption) => {
      caption.setAttribute("data-active", caption.id === this.state.active);
    });
  };

  initSlider() {
    const percolator = new Percolator();
    percolator.loadBehavior(this.slider, SlideCards, this.props);
  }

  bindSlideEvents() {
    this.slides.forEach((slide) => {
      slide.setAttribute("tabindex", 0);
      slide.addEventListener("mouseenter", this.onEnter);
      slide.addEventListener("mouseleave", this.onLeave);
      slide.addEventListener("focus", this.onEnter);
      slide.addEventListener("blur", this.onLeave);
    });
  }

  onEnter = ({ target }) => {
    const captionId = target.getAttribute("aria-describedby");
    if (!captionId || captionId === this.state.active) return;
    this.setState({ active: captionId });
  };

  onLeave = () => {
    this.setState({ active: null });
  };
}
