import React from "react";
import classNames from "classnames";
import FieldColumn from "../form/layout/FieldColumn";

export default function FieldsCard({
  columns,
  label,
  typeLabel,
  className,
  endDate,
  startDate,
  excludedDates,
}) {
  return columns ? (
    <fieldset className={classNames("m-form__fieldset", className)}>
      <legend>
        {label} | {typeLabel}
      </legend>
      <div className="m-form__fieldset--two-column">
        {columns.map((column, i) => (
          <FieldColumn
            key={i}
            fields={column}
            endDate={endDate}
            startDate={startDate}
            excludedDates={excludedDates}
          />
        ))}
      </div>
    </fieldset>
  ) : null;
}
