import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import ScrollLock from "cic-scroll-lock";

export default class OpenOverlay extends Sb {
  constructor(el, props, refs) {
    super();
    this.scrollLock = new ScrollLock();
    this.state = {
      open: !!props.open,
      initClass: refs.overlay.className,
    };

    this.refs = refs;
    this.update();
    this.bindEvents();
  }

  update = () => {
    const overlay = this.refs.overlay;
    const overlayClass = classNames(this.state.initClass, {
      hidden: !this.state.open,
      visible: this.state.open,
    });

    overlay.className = overlayClass;
  };

  bindEvents() {
    const { open, close } = this.refs;
    open.addEventListener("click", this.openOverlay);
    [...close].forEach((closeButton) => {
      closeButton.addEventListener("click", this.closeOverlay);
    });
  }

  closeOnEscape = (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Esc" ||
      event.keyCode === 27 ||
      event.which === 27
    ) {
      this.closeOverlay(event);
    }
  };

  disableScroll = () => {
    document.body.classList.add("no-scroll");
    this.scrollLock.only(this.refs.overlay);
  };

  enableScroll = () => {
    document.body.classList.remove("no-scroll");
    this.scrollLock.any(this.refs.overlay);
  };

  openOverlay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.addEventListener("keyup", this.closeOnEscape);
    this.setState({ open: true }, this.update);
    this.disableScroll();
  };

  closeOverlay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: false }, this.update);
    this.enableScroll();
    window.removeEventListener("keyup", this.closeOnEscape);
  };
}
