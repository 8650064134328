import AlignSubmenu from "./AlignSubmenu";
import ControlSubmenus from "./ControlSubmenus";
import LoadPageContent from "./LoadPageContent/Controller";
import ReduceHeader from "./ReduceHeader";
import RevealNav from "./RevealNav";
import ToggleDisclosure from "./ToggleDisclosure";

export default {
  AlignSubmenu,
  ControlSubmenus,
  LoadPageContent,
  ReduceHeader,
  RevealNav,
  ToggleDisclosure,
};
