import React from "react";
import PropTypes from "prop-types";

export default class PastExhibitions extends React.Component {
  static displayName = "PastExhibitions.Wrapper";

  static propTypes = {
    hasNext: PropTypes.bool.isRequired,
    hasPrevious: PropTypes.bool.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    next: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    previous: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    next: "Next",
    previous: "Previous",
  };

  click(event, direction) {
    event.preventDefault();
    if (direction === "next") {
      return this.props.onNext(event);
    }

    return this.props.onPrevious(event);
  }

  render() {
    const { hasPrevious, hasNext, previous, next } = this.props;

    return (
      <div className="pagination-primary">
        {hasPrevious && (
          <a href="#" className="prev" onClick={(e) => this.click(e, "prev")}>
            {previous}
          </a>
        )}
        {hasNext && (
          <a href="#" className="next" onClick={(e) => this.click(e, "next")}>
            {next}
          </a>
        )}
      </div>
    );
  }
}
