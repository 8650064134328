import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class ToggleDisclosure extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
    };

    this.refs = refs;
    this.initDisclosureClass = refs.disclosure.className;
    this.openHeight = this.getHeight(refs.disclosure);

    this.update();
    this.bindToggle();
  }

  get focusable() {
    return Array.from(
      this.refs.disclosure.querySelectorAll(
        'summary, button:not(:disabled), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])' // eslint-disable-line max-len
      )
    );
  }

  update = () => {
    const { open } = this.state;
    const { toggle, disclosure } = this.refs;

    toggle.setAttribute("aria-expanded", open ? "true" : "false");

    disclosure.className = classNames(this.initDisclosureClass, {
      open: open,
      closed: !open,
    });

    // disable/enable keyboard tabbing based on state.open
    this.focusable.forEach((el) => {
      el.setAttribute("tabindex", this.state.open ? 0 : -1);
    });

    const updatedHeight = `${open ? this.openHeight : 0}px`;
    Object.assign(disclosure.style, { height: updatedHeight });
  };

  bindToggle() {
    this.refs.toggle.addEventListener("click", this.handleClick);
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  getHeight(el) {
    return el.getBoundingClientRect().height;
  }
}
