import React, { PureComponent, Fragment } from "react";
import AnimateLoading from "behaviors/site/AnimateLoading";
import PropTypes from "prop-types";

/*
 * Adds a loading icon and lessens the opacity of any child elements
 * when the loading prop is set to true. If you want to add the
 * loading icon but not change the opacity, then do not pass in any
 * child elements.
 */
export default class Loader extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    // Instantiate loading animation
    this.loader = new AnimateLoading(this.wrapper);
  }

  componentDidUpdate() {
    if (this.props.loading) {
      this.loader.loading();
    } else {
      this.loader.loaded();
    }
  }

  get hasChildren() {
    return React.Children.count(this.props.children) > 0;
  }

  get loadingIcon() {
    return (
      <div
        className="loader"
        ref={(l) => {
          this.wrapper = l;
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 46.709 60"
          width="46.709"
          height="60"
        >
          <defs>
            <clipPath id="loader-clip-svg">
              <path
                d="M7.558 60H0V0h7.304L39.15 44.968V0h7.558v60h-6.2L7.56 13.29z"
                fill="none"
              />
            </clipPath>
          </defs>
          <rect
            x="0.2798206"
            y="60"
            width="46.709"
            height="60"
            clipPath="url(#loader-clip-svg)"
          />
        </svg>
      </div>
    );
  }

  get opacityWrapperClass() {
    return this.props.loading ? "loading" : "";
  }

  render() {
    if (!this.hasChildren) {
      return this.loadingIcon;
    }

    return (
      <Fragment>
        {this.loadingIcon}
        <div className={`load-status ${this.opacityWrapperClass}`}>
          {this.props.children}
        </div>
      </Fragment>
    );
  }
}
