/*
Polyfill to support usage of <picture> element and CSS property object-fit
in homepage hero slider. Detects feature support in browser. If unsupported,
replaces default and mobile images in <picture> with divs that have src as a
background image. Uses a listener on window resize to display/hide divs as
appropriate.
*/
import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";

export default class ObjectFit extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.state = {
      mobile: this.isMobile,
    };

    this.src = "";
    this.image = this.imageSrc;

    // Bail immediately if object-fit is supported
    if (this.image.style.objectFit !== undefined) return;

    [refs.mobileImage, refs.defaultImage].forEach((ref) => {
      if (!ref) return null;
      this.createBgImageEl(ref);
    });

    if (refs.mobileImage) {
      this.update();
      this.bindResize();
    }
  }

  get isMobile() {
    return document.documentElement.clientWidth < this.props.breakpoint;
  }

  get imageSrc() {
    const { mobileImage, defaultImage } = this.refs;

    if (!mobileImage || !this.state.mobile) return defaultImage;

    return mobileImage;
  }

  get bgImageClass() {
    return this.refs.defaultImage.className;
  }

  update = () => {
    const { mobileImage, defaultImage } = this.refs;

    if (!mobileImage) return;

    this.setDisplayProperty(mobileImage, this.state.mobile);
    this.setDisplayProperty(defaultImage, !this.state.mobile);
  };

  bindResize() {
    // Set overlay to closed state if at wider viewport width
    const debouncedClientSize = debounce(() => {
      this.setState({
        mobile: this.isMobile,
      });
    }, 200);

    window.addEventListener("resize", debouncedClientSize);
  }

  createBgImageEl = (el) => {
    const src = el.dataset.src;
    const bgImageEl = document.createElement("div");

    bgImageEl.className = this.bgImageClass;
    bgImageEl.dataset.ref = el.dataset.ref;

    // replace original refs
    this.refs[el.dataset.ref] = bgImageEl;

    Object.assign(bgImageEl.style, {
      backgroundImage: `url("${src}")`,
      backgroundSize: "cover",
    });

    // If props for objectPosition are passed,
    // apply them as backgroundPosition
    if (this.props.objectPosition) {
      const { x, y } = this.props.objectPosition;
      Object.assign(bgImageEl.style, { backgroundPosition: `${x}% ${y}%` });
    } else {
      Object.assign(bgImageEl.style, { backgroundPosition: `50% 50%` });
    }

    const parentNode = el.parentNode;

    parentNode.replaceChild(bgImageEl, el);
  };

  setDisplayProperty(el, show) {
    Object.assign(el.style, { display: show ? "block" : "none" });
  }
}
