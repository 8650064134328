import { format } from "date-fns";
import moment from "moment";

export default class DateUtility {
  static stringToDate(dateString) {
    // append time and time zone to ensure Date date isn't UTC
    const jsDate = dateString.concat("T09:00-04:00");
    return new Date(jsDate);
  }

  static dayFormat(dateString, dateObject = null) {
    // Can take a string or object for maximum performance
    // Swaps a php date string for design formatted "September 29"
    const date = dateObject || DateUtility.stringToDate(dateString);
    return format(date, "MMMM d");
  }

  static getRelativeDayName(date) {
    // Returns the name of the day or Today, Tomorrow, Yesterday
    const today = new Date();

    switch (date.toDateString()) {
      case today.toDateString():
        return "Today";
      case moment(today).add(1, "d").toDate().toDateString():
        return "Tomorrow";
      case moment(today).subtract(1, "d").toDate().toDateString():
        return "Yesterday";
    }

    return format(date, "EEEE");
  }
}
