import classNames from "classnames";
import Sb from "../abstract/StatefulBehavior";
import _ from "lodash";

export default class RevealSubmenu extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      hovering: false,
      animating: false,
      activatorClass: refs.activator.className,
      menuClass: refs.menu.className,
      menuBgClass: refs.menuBg.className,
    };

    // Create a class variable
    // To track for timeouts
    this.outTimer = "";

    this.refs = refs;
    this.props = props;
    this.el = el;

    this.bindEvents();
  }

  update = () => {
    const { activator, menu, menuBg } = this.refs;
    const state = this.state;

    activator.className = classNames(state.activatorClass, {
      open: state.hovering,
      closed: !state.hovering,
    });

    menu.className = classNames(state.menuClass, {
      open: state.hovering,
      closed: !state.hovering,
      animating: state.animating,
    });

    menuBg.className = classNames(state.menuBgClass, {
      open: state.hovering,
      closed: !state.hovering,
      animating: state.animating,
    });

    // Ask parent to close siblings when open
    if (this.state.hovering) {
      this.props.onHover(this.props.id);
    }
  };

  hoverIn = (animate = false) => {
    // Prevent timeout from setting false
    const animating = !this.state.hovering && animate;
    clearTimeout(this.outTimer);
    this.setState({
      hovering: true,
      animating,
    });
  };

  hoverOut = (delay = true) => {
    // Ensure timout doesn't run twice
    clearTimeout(this.outTimer);

    if (delay) {
      this.outTimer = setTimeout(() => {
        this.setState({
          hovering: false,
          animating: false,
        });
      }, 300);
    } else {
      this.setState({
        hovering: false,
        animating: false,
      });
    }
  };

  touchToggle = () => {
    if (!this.props.touchBehavior) return;
    this.state.hovering ? this.hoverOut(false) : this.hoverIn(true);
  };

  close() {
    // Called by parent function to close all
    // but the active dropdown
    // Ensure that timer doesn't run after close
    clearTimeout(this.outTimer);
    this.setState({ hovering: false });
  }

  bindEvents() {
    const { activator, menu } = this.refs;

    // show animation if not already hovering
    // set hovering to true
    activator.addEventListener("mouseenter", (e) => {
      if (_.hasIn(e, "relatedTarget") && e.relatedTarget !== null) {
        this.hoverIn(true);
      }
    });

    // Set a timeout to close menu
    activator.addEventListener("mouseleave", (e) => {
      this.hoverOut(false);
    });

    // open and close submenu on touch
    activator.addEventListener("touchstart", (e) => {
      this.touchToggle();
    });

    // set hovering to true (without showing animation)
    menu.addEventListener("mouseenter", this.hoverIn);

    // Set a timeout to close menu
    menu.addEventListener("mouseleave", this.hoverOut);
  }
}
