import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import VideoPlayer from "../Video";
import Text from "../Video/Text";

export default class SingleVideo extends PureComponent {
  static displayName = "Content.SingleVideo";

  static propTypes = {
    video: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { id, image, title, description } = this.props.video;

    return (
      <VideoPlayer videoId={id}>
        <figure
          style={{
            backgroundImage: `url('${image}')`,
          }}
        />
        <Text
          parentClassName="video-teaser-grid"
          description={description}
          title={title}
        />
      </VideoPlayer>
    );
  }
}
