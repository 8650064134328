import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class MembershipTabContent extends PureComponent {
  static displayName = "Content.ContentTabs.MembershipTabContent";

  static propTypes = {
    tab: PropTypes.object,
    addClass: PropTypes.string,
  };

  render() {
    const { tab, addClass } = this.props;
    const hasLink = tab.url && tab.link_text;

    const baseClass = "level";
    const levelClass = addClass ? baseClass + ` ${addClass}` : baseClass;

    return (
      <div className={levelClass}>
        <div
          className="benefits-list content-rte"
          dangerouslySetInnerHTML={{
            __html: tab.benefits,
          }}
        />
        {tab.deduction && (
          <span className="deduction">{tab.deduction} tax deductible</span>
        )}
        {hasLink && (
          <div className="cta">
            <a className="button-primary" href={tab.url}>
              {tab.link_text}
            </a>
          </div>
        )}
      </div>
    );
  }
}
