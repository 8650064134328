import React from "react";
import classNames from "classnames";

const CONFIRM_LABELS = {
  virtual_tour: "I have read the Virtual Tour Guidelines",
  guided_tour: "I have read the School Tour Guidelines",
  self_guided_tour: "I have read the Teacher-Led Tour Guidelines",
};

const CONFIRM_HEADERS = {
  virtual_tour: "Virtual Tour Guidelines",
  guided_tour: "Guided Tour Guidelines",
  self_guided_tour: "Teacher-Led Tour Guidelines",
};

export default function SubmitCard({
  className,
  handleAccept,
  tourType,
  guidelines,
}) {
  return (
    <div
      className={classNames(
        "m-form__card--not-fieldset m-form__card--dark content-default",
        className
      )}
    >
      <h4>{CONFIRM_HEADERS[tourType]}</h4>
      <div
        className="content-rte"
        dangerouslySetInnerHTML={{ __html: guidelines[tourType] }}
      ></div>
      <br />
      <div className="m-form__field">
        <p className="m-form__field-descript">
          By checking the box below, you acknowledge that you have read and
          accept the above guidelines.
        </p>
        <div className="m-form__checkbox-wrapper heading-tertiary">
          <input
            id="guidelines"
            type="checkbox"
            name="confirm_guidelines"
            value="1"
            className="m-form__checkbox--lg"
            required
          />
          <label htmlFor="guidelines">
            {CONFIRM_LABELS[tourType]}{" "}
            <span style={{ color: "#EF867F" }}>*</span>
          </label>
        </div>
      </div>
      <div className="m-form__checkbox-wrapper heading-tertiary">
        <input
          id="principal_approval"
          type="checkbox"
          name="confirm_principal_approval"
          value="1"
          className="m-form__checkbox--lg"
          onChange={handleAccept}
          required
        />
        <label htmlFor="principal_approval">
          I agree that my principal has reviewed and approved my field trip
          dates <span style={{ color: "#EF867F" }}>*</span>
        </label>
      </div>
      <div className="m-form__comments-wrapper">
        <label htmlFor="comments" className="m-form__label">
          Additional Notes or Comments
        </label>
        <textarea
          id="comments"
          name="comments"
          className="m-form__comments-textarea"
          rows="20"
          cols="50"
        />
      </div>
    </div>
  );
}
