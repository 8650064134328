import React from "react";

export default function TextInput({
  disabled,
  placeholder,
  required,
  name,
  ...inputProps
}) {
  return (
    <input
      id={name}
      name={name}
      className="m-form__input"
      aria-disabled={disabled}
      placeholder={placeholder && placeholder}
      required={required}
      {...inputProps}
    />
  );
}
