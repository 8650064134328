import AlertOverlay from "./AlertOverlay";
import BgHoverImage from "./BgHoverImage";
import Expand from "./Expand";
import ObjectFit from "./ObjectFit";
import OpenImageGridSlideshow from "./OpenImageGridSlideshow";
import OpenOverlay from "./OpenOverlay";
import PaginateAsync from "./PaginateAsync";
import ResponsiveBackgroundImage from "./ResponsiveBackgroundImage";
import ShrinkHero from "./ShrinkHero";
import SlideCards from "./SlideCards";
import SlideGalleryImages from "./SlideGalleryImages";
import SlideNav from "./SlideNav";

export default {
  AlertOverlay,
  BgHoverImage,
  Expand,
  ObjectFit,
  OpenImageGridSlideshow,
  OpenOverlay,
  PaginateAsync,
  ResponsiveBackgroundImage,
  ShrinkHero,
  SlideCards,
  SlideGalleryImages,
  SlideNav,
};
