import anime from "animejs";

export default class AnimateLoading {
  constructor(el) {
    this.el = el;

    // Track animation so that it can
    // be controlled/modified in different functions
    this.animation = null;
  }

  loading() {
    if (this.animation) return;
    this.animation = anime({
      targets: this.el.querySelector("rect"),
      y: [
        { value: 60, duration: 0 },
        { value: 0, duration: 500, delay: 500, easing: "easeInOutCubic" },
        { value: 60, duration: 200, delay: 500 },
      ],
      opacity: [
        { value: 0, duration: 0 },
        { value: 1, duration: 500, delay: 400, easing: "easeInOutCubic" },
        { value: 0, duration: 200, delay: 300, easing: "easeInOutCubic" },
      ],
      loop: true,
    });
  }

  loaded() {
    if (this.animation) this.animation.pause();
    this.animation = anime({
      targets: this.el.querySelector("rect"),
      y: {
        value: 60,
        duration: 200,
        easing: "easeInOutCubic",
      },
      opacity: { value: 0, duration: 200, easing: "easeInOutCubic" },
      complete: () => {
        this.animation = null;
      },
    });
  }
}
