import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class BgHoverImage extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      activeImage: null,
    };

    this.props = props;
    this.refs = refs;

    this.loadImages();
    this.update();
    this.bindHoverEvents();
  }

  loadImages() {
    const touch = this.hasTouchSupport();
    // don't load background images on touch devices
    if (touch) return;

    const imageEls = this.refs.image;
    const { imageList } = this.props;

    [...imageEls].forEach((imageEl, key) => {
      // create a dummy object to listen for image src load
      const dummyImage = document.createElement("img");
      dummyImage.src = Object.values(imageList)[key];
      // only add background-image once src is loaded,
      // to prevent `mouseover` callback from showing a semi-loaded image
      dummyImage.addEventListener("load", () => {
        Object.assign(imageEl.style, {
          backgroundImage: `url('${dummyImage.src}')`,
        });
      });
    });
  }

  update = () => {
    const { activeImage } = this.state;
    const images = this.refs.image;
    [...images].forEach((image, key) => {
      image.className = classNames({
        active: key === activeImage,
      });
    });
  };

  bindHoverEvents() {
    const { imageList } = this.props;
    const links = this.refs.link;

    [...links].forEach((link, key) => {
      const id = link.dataset.id;
      if (Object.prototype.hasOwnProperty.call(imageList, id)) {
        link.addEventListener("mouseover", () => {
          this.setState({ activeImage: key });
        });
        link.addEventListener("mouseout", () => {
          this.setState({ activeImage: null });
        });
      }
    });
  }

  hasTouchSupport() {
    return (
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0
    );
  }
}
