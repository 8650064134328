import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { debounce } from "lodash";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import MembershipTabContent from "./MembershipTabContent";
import CalendarTabContent from "./CalendarTabContent";
import Content from "./Content";

export default class ContentTabs extends PureComponent {
  static displayName = "Content.ContentTabs.Wrapper";

  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.array,
    content_type: PropTypes.string,
    showOverflow: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    content_type: "default",
  };

  constructor(props) {
    super();

    this.state = {
      active: 0,
      markerWidth: 0,
      markerLeft: 0,
      contentHeight: "auto",
    };
  }

  componentDidMount() {
    this.buildMarker();
    this.setContentHeight();

    this.debouncedMarker = debounce(this.buildMarker, 200);
    window.addEventListener("resize", this.debouncedMarker);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      this.buildMarker();
      setTimeout(() => {
        this.setContentHeight();
      }, 200);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedMarker);
  }

  get panelClassName() {
    return classNames({
      panels: true,
      overflow: this.props.showOverflow,
    });
  }

  setActive(active) {
    this.setState({ active });
  }

  setContentHeight = () => {
    if (this.contentEl) {
      const activeTabContent = this.contentEl.querySelector(
        `[data-index="${this.state.active}"] .level`
      );

      if (activeTabContent) {
        const activeTabHeight = activeTabContent.clientHeight;
        this.setState({ contentHeight: activeTabHeight + "px" });
      }
    }
  };

  buildMarker = () => {
    const b = this["b" + this.state.active];
    const parentLeft = b.parentNode.getBoundingClientRect().left;
    const markerWidth = b.offsetWidth + "px";
    const rect = b.getBoundingClientRect();
    const markerLeft = rect.left - parentLeft + "px";

    this.setState({
      markerWidth,
      markerLeft,
    });
  };

  renderHeading() {
    const tab = this.props.tabs[this.state.active];
    const hasHeadings = this.props.tabs.some((tab) => {
      return tab.heading;
    });

    if (!hasHeadings) return false;

    return (
      <ReactCSSTransitionGroup
        component="header"
        transitionName="fade"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
      >
        <h3 className="heading" key={"heading-" + this.state.active}>
          {tab.heading}
        </h3>
      </ReactCSSTransitionGroup>
    );
  }

  renderMenu() {
    const tabs = this.props.tabs;

    return (
      <nav className="tab-nav">
        <ul>
          {tabs.map((tab, index) => {
            return (
              <li
                key={"tab-" + index}
                className={index === this.state.active ? "active" : null}
                ref={(b) => {
                  this["b" + index] = b;
                }}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setActive(index);
                  }}
                >
                  {tab.tab_name}
                </a>
              </li>
            );
          })}
        </ul>
        <div
          className="tab-marker"
          style={{
            width: this.state.markerWidth,
            left: this.state.markerLeft,
          }}
        />
      </nav>
    );
  }

  renderPlaceholders(Template) {
    const tabs = this.props.tabs;

    return (
      <div
        className="placeholder-list"
        aria-hidden="true"
        style={{ width: tabs.length + "00%" }}
      >
        {tabs.map((tab, index) => {
          return <Template key={index} tab={tab} addClass="placeholder" />;
        })}
      </div>
    );
  }

  renderPanels(Template) {
    const { tabs } = this.props;

    return (
      <div
        className={this.panelClassName}
        ref={(el) => (this.contentEl = el)}
        style={{
          height: this.state.contentHeight,
          transition: "height 0.4s ease-out",
        }}
      >
        <ReactCSSTransitionGroup
          component="div"
          transitionName="fade"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
        >
          {tabs.map((tab, index) => (
            <div
              key={`content-${index}`}
              className={`tab-content-item ${
                index === this.state.active ? "active" : ""
              }`}
              data-index={index}
              style={{
                display: index === this.state.active ? "block" : "none",
              }}
            >
              <Template tab={tab} />
            </div>
          ))}
        </ReactCSSTransitionGroup>
      </div>
    );
  }

  render() {
    const contentComponents = {
      membership: MembershipTabContent,
      calendar: CalendarTabContent,
      default: Content,
    };

    const Template = contentComponents[this.props.content_type];

    return (
      <div className={`tab-content ${this.props.className}`}>
        {this.renderHeading()}
        {this.renderMenu()}
        {this.renderPanels(Template)}
      </div>
    );
  }
}
