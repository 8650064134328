import isValid from "date-fns/isValid";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import isEqual from "date-fns/isEqual";
import sub from "date-fns/sub";
import add from "date-fns/add";

function getDatesBetween(start, end) {
  const datesArray = [];
  const currentDate = new Date(start);
  const endDate = new Date(end);

  /* eslint-disable no-unmodified-loop-condition */
  while (currentDate <= endDate) {
    datesArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return datesArray;
}

const filterDay = (dateRange, day) => {
  return dateRange.filter((date) => {
    const dateDay = date.getDay();
    return dateDay === parseInt(day);
  });
};

const getDatesFromDays = (days, dateRange) =>
  days.reduce((filteredDates, day) => {
    const matches = filterDay(dateRange, day);
    return [...filteredDates, ...matches];
  }, []);

export const getExcludedDates = ({
  startDate,
  endDate,
  excludedDays,
  excludedDates,
}) => {
  const excludedDatesToObjects = excludedDates.map((date) => new Date(date));

  if (typeof excludedDays === "string" || !excludedDays)
    return excludedDatesToObjects;

  const includedDateRange = getDatesBetween(startDate, endDate);
  const excludedDatesFromDays = getDatesFromDays(
    excludedDays,
    includedDateRange
  );

  return [...excludedDatesToObjects, ...excludedDatesFromDays];
};

export const isAvailableDate = ({
  submittedDate,
  startDate,
  endDate,
  excludedDates,
}) => {
  if (!isValid(new Date(submittedDate))) return false;

  const submittedObj = new Date(submittedDate);
  const startObj = new Date(startDate);
  const adjustedStartObj = sub(startObj, { days: 1 });
  const endObj = new Date(endDate);
  const adjustedEndObj = add(endObj, { days: 1 });

  if (isBefore(submittedObj, adjustedStartObj)) return false;
  if (isAfter(submittedObj, adjustedEndObj)) return false;

  for (const date of excludedDates) {
    if (isEqual(date, submittedObj)) return false;
  }

  return true;
};
