import React from "react";
import classNames from "classnames";

export default function TextInput({
  disabled,
  label,
  name,
  wrapperType,
  showLabel,
  required,
  ...inputProps
}) {
  return (
    <div
      className={showLabel ? "m-form__field" : `m-form__${wrapperType}-wrapper`}
    >
      <label
        htmlFor={`${name}_text`}
        className={
          showLabel
            ? classNames("m-form__label", {
                "m-form__required": required,
              })
            : "screen-reader-text"
        }
      >
        {label}
      </label>
      <input
        id={`${name}_text`}
        type="text"
        name={name}
        className="m-form__input"
        placeholder={showLabel ? null : "Tell us more"}
        required={required}
        aria-disabled={disabled}
        {...inputProps}
      />
    </div>
  );
}
