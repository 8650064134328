import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";

import Select from "components/site/Select";
import Loader from "components/site/Loader";
import Paginate from "components/site/Paginate";
import VideoGrid from "./Grid";
import { videosApiRequest } from "api";

export default class VideoLibrary extends Component {
  static displayName = "Content.VideoLibrary";

  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  };

  constructor(props) {
    super(props);

    const filters = {
      category_id: "default",
      sort: "newest",
      page: 1,
    };

    this.state = {
      filters,
      pageChange: false,
      loading: false,
      videos: [],
      pagination: {},
    };

    videosApiRequest(filters, (success) =>
      this.setState({
        videos: success.records,
        pagination: success.pagination,
        loading: false,
      })
    );

    this.handleFiltering = this.handleFiltering.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchVideos = this.fetchVideos.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.libraryRef = createRef();
  }

  componentDidUpdate() {
    if (this.state.pageChange) {
      this.scrollToTop();
    }
  }

  get headerHeight() {
    const headerClass = document.getElementsByClassName("site-header");
    if (headerClass.length >= 1) {
      return headerClass[0].clientHeight;
    }

    return 0;
  }

  get gridDistanceFromTop() {
    return (
      this.libraryRef.current.getBoundingClientRect().top + window.pageYOffset
    );
  }

  get hasNext() {
    return this.state.pagination.currentPage < this.state.pagination.totalPages;
  }

  get hasPrevious() {
    return this.state.pagination.currentPage > 1;
  }

  scrollToTop() {
    // add an extra 30px to give some breathing room
    const top = this.gridDistanceFromTop - this.headerHeight - 30;

    window.scrollTo({ top, behavior: "smooth" });
  }

  fetchVideos(filters) {
    videosApiRequest(filters, (success) =>
      this.setState({
        videos: success.records,
        pagination: success.pagination,
        pageChange: false,
        loading: false,
      })
    );
  }

  handlePageChange(value, page = 1) {
    this.setState((prevState) => ({ pageChange: true, loading: true }));
    this.fetchVideos(Object.assign(this.state.filters, { page }));
  }

  handleFiltering(value) {
    this.setState((prevState) => ({
      filters: Object.assign(prevState.filters, value),
      loading: true,
    }));

    this.fetchVideos(Object.assign(this.state.filters, value, { page: 1 }));
  }

  render() {
    const { currentPage } = this.state.pagination;
    return (
      <Fragment>
        <div className="filters" ref={this.libraryRef}>
          <h2 className="filters__title">Videos</h2>

          <div className="filters__dropdown-container">
            <Select
              parentClassName="filters"
              name="sort"
              fields={[
                { id: "newest", title: "Newest" },
                { id: "oldest", title: "Oldest" },
                { id: "a-z", title: "A - Z" },
                { id: "z-a", title: "Z - A" },
              ]}
              value={this.state.filters.sort}
              onChange={this.handleFiltering}
            />

            <Select
              parentClassName="filters"
              name="category_id"
              fields={this.props.categories}
              default="All Categories"
              value={this.state.filters.category_id}
              onChange={this.handleFiltering}
            />
          </div>
        </div>

        <Loader loading={this.state.loading}>
          <VideoGrid videos={this.state.videos} />
        </Loader>

        {this.state.pagination.totalPages > 1 && (
          <div className="video-library__pagination">
            <Paginate
              hasNext={this.hasNext}
              hasPrevious={this.hasPrevious}
              onNext={(event) => this.handlePageChange(event, currentPage + 1)}
              onPrevious={(event) =>
                this.handlePageChange(event, currentPage - 1)
              }
            />
          </div>
        )}
      </Fragment>
    );
  }
}
