import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class SlideshowCaption extends PureComponent {
  static displayName = "Content.SlideshowCallout.Caption";

  static propTypes = {
    data: PropTypes.object,
    width: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { width, url, modal, label, heading, body, linkText } =
      this.props.data;

    return (
      <div
        className="caption"
        style={{
          maxWidth: width && width,
        }}
      >
        <header>
          {label && <span className="label">{label}</span>}
          {heading && <h3>{heading}</h3>}
        </header>
        {body && (
          <div
            className="body content-rte"
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        )}
        {modal && (
          <div className="button-secondary" onClick={this.props.onClick}>
            {linkText}
          </div>
        )}
        {url && (
          <a className="button-secondary" href={url}>
            {linkText}
          </a>
        )}
      </div>
    );
  }
}
