import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class TabContent extends PureComponent {
  static displayName = "Content.ContentTabs.Content";

  static propTypes = {
    tab: PropTypes.object,
    addClass: PropTypes.string,
  };

  render() {
    const { tab, addClass } = this.props;

    const baseClass = "level";
    const levelClass = addClass ? baseClass + ` ${addClass}` : baseClass;

    return (
      <div
        className={levelClass}
        dangerouslySetInnerHTML={{
          __html: tab.body,
        }}
      />
    );
  }
}
