import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";

export default class List extends PureComponent {
  static propTypes = {
    exhibitions: PropTypes.array,
  };

  static dateFormat = "MMMM d, yyyy";

  DateRange(props) {
    const openDate = parseISO(props.open);
    const closeDate = parseISO(props.close);
    const openString = format(openDate, List.dateFormat);
    const closeString = format(closeDate, List.dateFormat);

    return (
      <span className="date">
        {openString} - {closeString}
      </span>
    );
  }

  render() {
    const { exhibitions } = this.props;

    let list;

    if (exhibitions.length > 0) {
      list = this.renderList(exhibitions);
    } else {
      list = this.renderNoResults();
    }

    return <ul className="exhibition-list">{list}</ul>;
  }

  renderList(exhibitions) {
    return exhibitions.map((exhibition, index) => {
      return (
        <li key={index} className="item">
          <a href={exhibition.url}>
            <h3
              className="title"
              dangerouslySetInnerHTML={{ __html: exhibition.display_title }}
            />
            <this.DateRange open={exhibition.open} close={exhibition.close} />
          </a>
        </li>
      );
    });
  }

  renderNoResults() {
    return (
      <li className="item">
        <h3 className="title">
          There are no exhibitions that match this query.
        </h3>
        <span className="date">
          Search in another year or click “All” to reset the filters.
        </span>
      </li>
    );
  }
}
