import "whatwg-fetch";
import qs from "query-string";

function handleError(response) {
  /* eslint-disable no-console */
  console.log("Pages API request failed to complete due to unexpected error");
  /* eslint-enable no-console */
}

function handleBadStatus(response) {
  /* eslint-disable no-console */
  console.log(`API Request completed but status was ${response.status}`);
  /* eslint-enable no-console */
}

function handleSuccess(response) {
  if (response.status !== 200) {
    handleBadStatus(response);
  }
}

export default function exhibitionsApiRequest(params) {
  const path = "/api/v1/exhibition";
  const url = params ? path + "?" + qs.stringify(params) : path;
  const fetchPromise = window.fetch(url);
  fetchPromise.then(handleSuccess, handleError);
  return fetchPromise;
}
