import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import Cookies from "js-cookie";
import ScrollLock from "cic-scroll-lock";

const COOKIE_NAME = "norton-alert-dismissed";

export default class AlertOverlay extends Sb {
  constructor(el, props, refs) {
    super();

    this.refs = refs;
    this.props = props;
    this.scrollLock = new ScrollLock();

    this.state = {
      open: this.shouldOpenAlert,
      initClass: refs.overlay.className,
    };

    if (this.shouldOpenAlert) {
      this.scrollLock.only(this.refs.overlay);
      window.addEventListener("keyup", this.closeOnEscape);
    }

    this.update();
    this.bindEvents();
  }

  get duration() {
    const { duration } = this.props;
    if (duration === "session") return duration;
    return parseFloat(duration);
  }

  get canUseStorage() {
    const test = Math.random().toString();
    let result = false;

    try {
      window.sessionStorage.setItem("storage-test", test);
      result = window.sessionStorage.getItem("storage-test") === test;
      window.sessionStorage.removeItem("storage-test");
    } catch (error) {}

    return result;
  }

  get shouldOpenAlert() {
    if (this.props.open === "false") return false;

    if (this.props.duration === "session") {
      // if UA doesn't support sessionStorage or blocks access to it,
      // just don't show alert
      if (!this.canUseStorage) return false;
      const sessionValue = window.sessionStorage.getItem(COOKIE_NAME);
      return sessionValue !== "1";
    } else {
      const cookieValue = Cookies.get(COOKIE_NAME);
      return typeof cookieValue === "undefined";
    }
  }

  update = () => {
    const overlay = this.refs.overlay;
    const overlayClass = classNames(this.state.initClass, {
      hidden: !this.state.open,
      visible: this.state.open,
    });

    overlay.className = overlayClass;
  };

  bindEvents() {
    const { close, copy } = this.refs;
    const inlineLinks = copy && copy.querySelectorAll("[href]");
    [...close].forEach((closeButton) => {
      closeButton.addEventListener("click", this.closeByButton);
    });
    [...inlineLinks].forEach((link) => {
      link.addEventListener("click", this.closeByLink);
    });
  }

  enableScroll = () => {
    document.body.classList.remove("no-scroll");
    this.scrollLock.any(this.refs.overlay);
  };

  closeOnEscape = (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Esc" ||
      event.keyCode === 27 ||
      event.which === 27
    ) {
      this.closeOverlay(event);
    }
  };

  closeByLink = () => {
    // close overlay, but still follow link (i.e. don't prevent default)
    this.duration === "session"
      ? this.updateSessionStorage()
      : this.updateCookie();
  };

  closeByButton = (event) => {
    // close overlay, but stay on page
    event.preventDefault();
    this.duration === "session"
      ? this.updateSessionStorage()
      : this.updateCookie();
    this.setState({ open: false }, this.update);
    this.enableScroll();
    window.removeEventListener("keyup", this.closeOnEscape);
  };

  updateSessionStorage() {
    window.sessionStorage.setItem(COOKIE_NAME, "1");
  }

  updateCookie() {
    const expires = this.duration;
    Cookies.set(COOKIE_NAME, "1", { expires });
  }
}
