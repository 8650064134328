import "whatwg-fetch";

export default function pageApiRequest(apiPath, onSuccess, onError) {
  const fetchPromise = window
    .fetch(apiPath, {
      headers: {
        Accept: "application/json",
      },
      credentials: "same-origin",
    })
    .then(
      (response) => {
        onSuccess(response);
      },
      (error) => {
        throw new Error(error);
      }
    )
    .catch((error) => {
      onError(error);
    });
  return fetchPromise;
}
