import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { format, addDays } from "date-fns";
import EventsAPIClient from "api/Events";
import Nav from "./Nav";
import EventList from "./List";
import Loader from "components/site/Loader";
import moment from "moment";
import dateUtility from "../../../helpers/dateUtility";
import _ from "lodash";

export default class Wrapper extends PureComponent {
  static displayName = "Calendar.Wrapper";

  static propTypes = {
    categories: PropTypes.array,
  };

  constructor(props) {
    super();

    const initialCategoryId = this.categoryIdFromSlug(
      window.location.hash.substr(1),
      props.categories
    );

    this.state = {
      // Triggered when api is loading
      loading: false,
      days: [],

      // Date string used to trigger api
      activeDay: props.day ? props.day : format(new Date(), "yyyy-MM-dd"),

      // Indicates a prev pagination request with filters (does nothing
      // otherwise)
      activeDayBefore: false,

      // The selected categories
      activeFilters: initialCategoryId !== null ? [initialCategoryId] : [],
      prevDate: null,
      nextDate: null,
    };

    this.apiClient = new EventsAPIClient();
  }

  categoryIdFromSlug(slug, categories) {
    if (slug === "") return null;
    return categories.reduce((acc, curr) => {
      return acc === null && slug === curr.slug ? curr.id : acc;
    }, null);
  }

  componentDidMount() {
    this.setItemsFromState(this.state.activeDay);
  }

  componentDidUpdate(prevProps, prevState) {
    // If active day has changed (via pagination or click)
    // Update the events list
    if (
      prevState.activeDay !== this.state.activeDay ||
      prevState.activeFilters !== this.state.activeFilters ||
      prevState.activeDayBefore !== this.state.activeDayBefore
    ) {
      this.setItemsFromState();
    }
  }

  setItemsFromState() {
    const params = {};
    params.date = this.state.activeDay;
    params.categories =
      this.state.activeFilters.length > 0
        ? this.state.activeFilters.join(",")
        : null;
    params.before = this.state.activeDayBefore;

    this.apiClient.fetchData("/api/v1/calendar", params, (data) => {
      this.setState({
        loading: false,
        days: data.days,
        prevDate: data.page.prev ? data.page.prev : null,
        nextDate: data.page.next ? data.page.next : null,
      });
    });
  }

  scrollToCalendarTop() {
    // const el = ReactDOM.findDOMNode(this);
    //
    // anime({
    //   targets: {y: window.pageYOffset},
    //   scrollTop: [0, el.offsetTop]
    // });
  }

  canAccessDate = (date) => {
    // Returns true if the passed date should be accessible in the calendar
    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);

    const compareDate = date;
    compareDate.setHours(0, 0, 0, 0);

    const maxDate = moment().add("2", "y");

    return minDate <= compareDate && maxDate >= compareDate;
  };

  maybeUpdateDay = (day) => {
    const formattedDay = format(day, "yyyy-MM-dd");

    // If it's not changing, don't actually update the state
    if (this.state.activeDay !== formattedDay) {
      // Don't allow pagination while loading
      this.setState({
        ...this.state,
        loading: true,
        activeDay: formattedDay,
        activeDayBefore: false,
      });
    }
  };

  selectDay = (day) => {
    // Active day is set as formatted string, and then converted
    // to date object when passed back to day picker
    if (!this.state.loading) {
      if (this.canAccessDate(day)) {
        this.maybeUpdateDay(day);
      }
    }
  };

  selectFilter = (id) => {
    if (!this.state.loading) {
      const activeFilters = _.xor(this.state.activeFilters, [id]);

      this.setState({
        loading: true,
        activeFilters: activeFilters,
        // Only clear activeDay if we're just beginning to filter with
        // categories
        activeDay: this.state.activeDay || "",
        activeDayBefore: this.state.activeDayBefore,
      });
    }
  };

  clearFilters = () => {
    if (!this.state.loading) {
      this.setState({
        ...this.state,
        loading: true,
        activeFilters: [],
      });
    }
  };

  paginateDay = (event, direction) => {
    event.preventDefault();
    const active = new Date(this.state.activeDay.replace("-", "/"));
    this.selectDay(addDays(active, direction));
  };

  paginateNextDay = (event) => {
    this.paginateDay(event, 1);
  };

  paginatePrevDay = (event) => {
    this.paginateDay(event, -1);
  };

  paginateFilterNext = (event, date) => {
    event.preventDefault();
    this.setState({
      activeDay: this.state.nextDate,
      activeDayBefore: false,
    });
  };

  paginateFilterPrev = (event, date) => {
    event.preventDefault();
    this.setState({
      activeDay: this.state.prevDate,
      activeDayBefore: true,
    });
  };

  render() {
    const { days, loading } = this.state;

    return (
      <div className="events-calendar">
        <Loader loading={loading}>
          <div className="wrapper">
            <div className="nav">
              <a href="#results">
                <span className="screen-reader-text">{"Skip to results"}</span>
              </a>
              <Nav
                handleDayClick={this.selectDay}
                selectedDay={dateUtility.stringToDate(this.state.activeDay)}
                filters={this.props.categories}
                selectedFilters={this.state.activeFilters}
                handleFilterClick={this.selectFilter}
                handleFilterClear={this.clearFilters}
                canAccessDate={this.canAccessDate}
              />
            </div>
            <a name="results" />
            <div className="results">
              <EventList days={days} />
              {this.renderCategoriesPagination()}
            </div>
          </div>
        </Loader>
      </div>
    );
  }

  renderCategoriesPagination() {
    const { prevDate, nextDate } = this.state;

    return (
      <div className="pagination-secondary">
        {prevDate && (
          <a href="#" className="prev" onClick={this.paginateFilterPrev}>
            <i className="icon icon-arrow"></i>
            <span>Previous</span>
          </a>
        )}

        {nextDate && (
          <a href="#" className="next" onClick={this.paginateFilterNext}>
            <span>Next</span>
            <i className="icon icon-arrow"></i>
          </a>
        )}
      </div>
    );
  }
}
