import React from "react";
import { format } from "date-fns";
import exhibitionsApiRequest from "api/Exhibitions";
import PropTypes from "prop-types";
import List from "./List";
import CategoryFilters from "./CategoryFilters";
import _ from "lodash";
import Loader from "components/site/Loader";
import anime from "animejs";
import Paginate from "components/site/Paginate";

export default class PastExhibitions extends React.Component {
  static displayName = "PastExhibitions.Wrapper";

  static propTypes = {
    categories: PropTypes.array,
  };

  constructor(props) {
    super();

    const currentYear = parseInt(format(new Date(), "yyyy"), 10);

    this.state = {
      loading: false,
      exhibitions: [],
      selectedCategories: [],
      requestYear: currentYear,
      displayYear: currentYear,
      minYear: null,
      maxYear: currentYear,
      // track the first user interaction so we know to scroll afterwards
      userInteraction: false,
    };

    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.handleCategoryClear = this.handleCategoryClear.bind(this);
  }

  componentDidMount() {
    this.setItemsFromState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.requestYear !== this.state.requestYear ||
      prevState.selectedCategories !== this.state.selectedCategories
    ) {
      this.setItemsFromState();
    }
  }

  setItemsFromState() {
    const { requestYear, selectedCategories } = this.state;
    const params = {
      year: requestYear,
      categories: selectedCategories.join(","),
    };

    exhibitionsApiRequest(params)
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        this.setState({
          loading: false,
          exhibitions: data.exhibitions,
          minYear: data.yearRange[0],
          maxYear: data.yearRange[1],
          displayYear: requestYear,
        });
      })
      .then(() => {
        this.maybeScroll();
      });
  }

  maybeScroll() {
    if (!this.state.userInteraction) return;
    anime({
      targets: "html, body",
      scrollTop: [window.pageYOffset, 0],
      duration: 1000,
      easing: "easeInOutCubic",
    });
  }

  handleYearChange(year, e) {
    e.preventDefault();
    this.setState({
      loading: true,
      requestYear: year,
      userInteraction: true,
    });
  }

  handleCategoryClick(category, e) {
    e.preventDefault();

    const { selectedCategories } = this.state;
    const newCategories = _.xor(selectedCategories, [category]);
    this.setState({
      loading: true,
      selectedCategories: newCategories,
      userInteraction: true,
    });
  }

  handleCategoryClear(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      selectedCategories: [],
      userInteraction: true,
    });
  }

  render() {
    const {
      exhibitions,
      requestYear,
      displayYear,
      minYear,
      maxYear,
      selectedCategories,
      loading,
    } = this.state;
    const { categories } = this.props;
    const hasPrevious = displayYear - 1 >= minYear;
    const hasNext = displayYear + 1 <= maxYear;
    const prevYear = hasPrevious ? displayYear - 1 : null;
    const nextYear = hasNext ? displayYear + 1 : null;

    return (
      <section className="exhibition-archive container">
        <Loader loading={loading}>
          <div className="wrapper">
            <List exhibitions={exhibitions} />
            <aside className="exhibition-sidebar">
              <div className="year-nav rel">
                <div className="current-year">{displayYear}</div>
                {prevYear && (
                  <button
                    aria-label="Next Year"
                    className="prev"
                    onClick={this.handleYearChange.bind(this, prevYear)}
                  ></button>
                )}
                {nextYear && (
                  <button
                    aria-label="Previous Year"
                    className="next"
                    onClick={this.handleYearChange.bind(this, nextYear)}
                  ></button>
                )}
              </div>
              <CategoryFilters
                categories={categories}
                handleCategoryClick={this.handleCategoryClick}
                handleCategoryClear={this.handleCategoryClear}
                selectedCategories={selectedCategories}
              />
            </aside>
          </div>

          <Paginate
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            onNext={this.handleYearChange.bind(this, nextYear)}
            onPrevious={this.handleYearChange.bind(this, prevYear)}
            next={nextYear}
            previous={prevYear}
          />
        </Loader>
      </section>
    );
  }
}
