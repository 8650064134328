import React, { useState } from "react";
import { Checkbox, OtherOption } from "../atomic";
import classNames from "classnames";

export default function CheckboxGroup({
  name,
  required,
  options = [],
  label,
  className,
  description,
  other,
  ...inputProps
}) {
  const [showOther, setShowOther] = useState(false);

  const otherOption = options.find((o) => o.value === "Other");
  const filteredOptions = otherOption
    ? options.filter((o) => o.value !== otherOption.value)
    : options;

  return (
    <div role="group" required={required} className={"m-form__field"}>
      <label
        className={classNames("m-form__label", {
          "m-form__required": required,
        })}
      >
        {label}
      </label>
      {description && (
        <p
          className="m-form__field-descript"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {filteredOptions.map(({ label, value }) => (
        <Checkbox
          name={name}
          key={value}
          label={label}
          value={value}
          {...inputProps}
        />
      ))}
      {other && (
        <Checkbox
          name={name}
          label={otherOption?.label}
          value={otherOption?.value}
          onChange={() => setShowOther(!showOther)}
        />
      )}
      {showOther && <OtherOption wrapperType="checkbox" {...other} />}
    </div>
  );
}
