import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class CategoryFilters extends PureComponent {
  static propTypes = {
    categories: PropTypes.array,
    handleCategoryClick: PropTypes.func,
    handleCategoryClear: PropTypes.func,
    selectedCategories: PropTypes.array,
  };

  constructor() {
    super();

    this.state = {
      open: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      const { listWrapper, placeholderList, toggle } = this.refs;
      if (this.state.open) {
        const listHeight =
          placeholderList.offsetHeight + toggle.offsetHeight + 60;
        Object.assign(listWrapper.style, { minHeight: listHeight + "px" });
      } else {
        Object.assign(listWrapper.style, { minHeight: "0px" });
      }
    }
  }

  toggleFilters = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const {
      categories,
      selectedCategories,
      handleCategoryClick,
      handleCategoryClear,
    } = this.props;
    const showingAll = selectedCategories.length === 0;
    const listClasses = classNames("filter-list", {
      open: this.state.open,
      closed: !this.state.open,
    });

    return (
      <div className={listClasses} ref="listWrapper">
        <button
          aria-hidden="true"
          className="show-all"
          ref="toggle"
          onClick={this.toggleFilters}
        >
          Filter
        </button>
        <div className="rel">
          <div className="full-placeholder" ref="placeholderList">
            <ul>
              <li>
                <button>All</button>
              </li>
              {categories.map(function (category) {
                return (
                  <li key={category.id}>
                    <button>{category.title}</button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="rel">
          <ul className="full">
            <li>
              <button
                className={"all " + (showingAll ? "active" : "")}
                onClick={(e) => {
                  handleCategoryClear(e);
                }}
              >
                All
              </button>
            </li>
            {categories.map(function (category) {
              return (
                <li
                  key={category.id}
                  onClick={(e) => {
                    handleCategoryClick(category.id, e);
                  }}
                >
                  <button
                    className={
                      selectedCategories.includes(category.id) ? "active" : ""
                    }
                  >
                    {category.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
