import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class Expand extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
      height: 0,
      initClass: el.className,
      animating: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    // Update once on load
    this.update();
    this.bindOpen();
    this.bindActivator();
    this.bindToggle();

    this.animationTimer = null;
  }

  bindOpen() {
    this.el.addEventListener("click", this.open);
  }

  bindActivator() {
    if (!this.refs.activator) return false;
    this.refs.activator.addEventListener("click", this.open);
  }

  bindToggle() {
    if (!this.refs.toggle) return false;
    this.refs.toggle.addEventListener("click", this.toggle);
  }

  update = (e) => {
    // Unbind opener on el if opened
    // If animating, set a timeout to cancel animation
    if (this.state.animating) {
      this.animationTimer = setTimeout(() => {
        this.setState({ animating: false });
      }, 350);
    }
    this.el.className = classNames(this.state.initClass, {
      open: this.state.open,
      closed: !this.state.open,
    });

    this.el.style.minHeight = this.state.height + "px";
  };

  open = (e) => {
    // Only open if already open
    if (this.state.open || this.state.animating) {
      return false;
    }

    // Get height of the element and placeholder and update state.
    // If there's a teaser (for Expandable Box CE), subtract that.
    const elHeight = this.refs.teaser
      ? this.el.offsetHeight - this.refs.teaser.offsetHeight
      : this.el.offsetHeight;
    const placeholderHeight = this.refs.placeholder.offsetHeight;

    this.setState({
      open: true,
      height: elHeight + placeholderHeight,
      animating: true,
    });
  };

  close = (e) => {
    this.setState({
      open: false,
      height: 0,
      animating: true,
    });
  };

  toggle = (e) => {
    // Don't update if animating
    if (this.state.animating) return false;
    // Don't click the wrapper also
    e.stopPropagation();
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  };
}
