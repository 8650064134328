import React, { useEffect, useState, useRef, useCallback } from "react";
import { formApiRequest } from "../../../api/Form.js";
import FormPagerLite from "./pager/FormPagerLite";
import SuccessCard from "./cards/SuccessCard";
import classNames from "classnames";
import { getExcludedDates, isAvailableDate } from "./helpers/dates";

export default function Form(props) {
  /* eslint-disable camelcase */
  const guidelines = {
    virtual_tour: props.virtual_guidelines,
    guided_tour: props.guided_guidelines,
    self_guided_tour: props.self_guided_guidelines,
  };

  const [formState, setFormState] = useState();
  const [errors, setErrors] = useState();
  const formRef = useRef();
  const sectionElement = document.getElementById("SchoolTourForms").parentNode;

  const calculatedEndDate = props.end_date ? new Date(props.end_date) : null;

  const start = new Date();
  start.setDate(start.getDate() + 21);
  const startDateObj = new Date(props.start_date);
  const calculatedStartDate = startDateObj > start ? startDateObj : start;
  // Check if start date falls after the end date
  const isInvalidDateRange = calculatedStartDate > calculatedEndDate;

  const excludedDates = getExcludedDates({
    startDate: calculatedStartDate,
    endDate: calculatedEndDate,
    excludedDays: props.exclude_weekdays,
    excludedDates: props.exclude_dates,
  });

  const onSuccess = useCallback(
    (response) => {
      setFormState("success");
      if (response.errors?.length) {
        setErrors(response.errors);
      }
      sectionElement.scrollIntoView();
    },
    [sectionElement]
  );
  const onError = useCallback((response) => {
    setFormState("error");
    if (response.errors?.length) {
      setErrors(response.errors);
    }
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setFormState("loading");

      const formData = new FormData(e.target);

      const reformatDate = (date) => {
        const dateParts = date.split("/");
        const reformattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
        return reformattedDate;
      };

      const preferredDate = formData.get("preferred_date");
      const alternativeDate = formData.get("alternative_date");

      const submittedInvalidDate = !isAvailableDate({
        submittedDate: preferredDate,
        startDate: calculatedStartDate,
        endDate: calculatedEndDate,
        excludedDates,
      });

      const submittedInvalidAltDate = !isAvailableDate({
        submittedDate: alternativeDate,
        startDate: calculatedStartDate,
        endDate: calculatedEndDate,
        excludedDates,
      });

      if (submittedInvalidDate || submittedInvalidAltDate) {
        const preferredDateMessage = submittedInvalidDate
          ? [
              "You have submitted an invalid preferred tour date. Please update and try again.",
            ]
          : [];

        const alternativeDateMessage = submittedInvalidAltDate
          ? [
              "You have submitted an invalid alternative tour date. Please update and try again.",
            ]
          : [];

        return setErrors([...preferredDateMessage, ...alternativeDateMessage]);
      }

      formData.set("preferred_date", reformatDate(preferredDate));
      formData.set("alternative_date", reformatDate(alternativeDate));

      formApiRequest(formData, onSuccess, onError);
    },
    [onSuccess, onError]
  );

  useEffect(() => {
    const form = formRef.current;
    if (form) {
      form.addEventListener("submit", onSubmit);
    }
    return () => {
      if (form) {
        form.removeEventListener("submit", onSubmit);
      }
    };
  }, [onSubmit]);

  /* Display an alternative message if start date is after the end date */
  if (isInvalidDateRange) {
    return (
      <div className="m-form">
        <header className="content-default m-form__header">
          <h1>School Tour Planning</h1>
          <p>
            Use this form to request a K-12 virtual or in-person class tour.
          </p>
        </header>
        <div className="m-form__right m-form__right">
          <div className="m-form__card--not-fieldset m-form__card--dark m-form__card--announcement content-default">
            <h3>There are currently no tour dates available.</h3>
            <p>
              Please contact{" "}
              <a href="mailto:schools@norton.org">schools@norton.org</a> or call
              561-832-5196 x1046 for further assistance.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <form ref={formRef} className="m-form">
        <header
          className={classNames("content-default m-form__header", {
            "m-form__header--success": formState === "success",
          })}
        >
          <h1>School Tour Planning</h1>
          <p>
            Use this form to request a K-12 virtual or in-person class tour.
          </p>
        </header>
        <div className="m-form__right m-form__right--stack">
          {formState === "success" ? (
            <SuccessCard />
          ) : (
            <>
              <FormPagerLite
                guidelines={guidelines}
                intro={props.intro}
                endDate={calculatedEndDate}
                startDate={calculatedStartDate}
                excludedDates={excludedDates}
              />
              {errors && (
                <div className="m-form__error content-default">
                  <p>Sorry! There was an error submitting your information.</p>
                  <ul>
                    {errors.map((message, i) => (
                      <li key={i}>{message}</li>
                    ))}
                  </ul>
                  <p>
                    If you are having trouble with the form, please reach out to
                    us at{" "}
                    <a href="mailto:schools@norton.org">schools@norton.org</a>{" "}
                    or 561-832-5196 x1046 to schedule your tour.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </>
  );
}
