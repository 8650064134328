import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class CustomSelect extends PureComponent {
  static displayName = "Select";

  static propTypes = {
    parentClassName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    default: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => null,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  option(id, title) {
    return (
      <option key={id} value={id}>
        {title}
      </option>
    );
  }

  handleChange(e) {
    this.props.onChange({ [this.props.name]: e.target.value });
  }

  render() {
    return (
      <div className={`${this.props.parentClassName}__select`}>
        {this.props.description && (
          <label htmlFor={this.props.name}>{this.props.description}</label>
        )}
        <select
          id={this.props.name}
          value={this.props.value || "default"}
          onChange={this.handleChange}
        >
          {this.props.default && (
            <option key="default" value="default">
              {this.props.default}
            </option>
          )}
          {this.props.fields.map((field) => this.option(field.id, field.title))}
        </select>
      </div>
    );
  }
}
