export const tourInfo = {
  label: "Tour Information",
  columns: [
    [
      {
        type: "text",
        name: "students",
        label: "Number of Students",
        description:
          "A maximum of 100 students per tour. <strong>40-50 student limit for Special Exhibition Tours.</strong> Groups will be touring as a class. 1 chaperone per 12 students is required.",
        required: true,
      },
      {
        type: "text",
        name: "teachers",
        label: "Number of Teachers",
        required: true,
      },
      {
        type: "text",
        name: "adults",
        label: "Number of Chaperones (1-12 ratio is required)",
        required: true,
      },
      {
        type: "text",
        name: "grade",
        label: "Grade Level(s)",
        required: true,
      },
      {
        type: "date",
        name: "preferred_date",
        label: "Preferred Tour Date",
        description:
          "Tours are available Mondays, Tuesdays, Thursdays, Fridays | 10am - 1pm (The Norton is taking tour requests through December 2023)",
        placeholder: "MM/DD/YYYY",
        required: true,
      },
      {
        type: "date",
        name: "alternative_date",
        label: "Alternative Tour Date",
        placeholder: "MM/DD/YYYY",
        required: true,
      },
    ],
    [
      {
        type: "radio",
        name: "tour_time",
        label: "Tour times (50-minute docent led tour) ",
        options: [
          { label: "10:00 AM", value: "10:00 AM" },
          { label: "11:00 AM", value: "11:00 AM" },
          { label: "12:00 PM", value: "12:00 PM" },
          { label: "1:00 PM", value: "1:00 PM" },
          { label: "Other", value: "Other" },
        ],
        required: true,
        other: { name: "tour_time_other", label: "Other Tour Time" },
      },
      {
        type: "radio",
        name: "theme",
        label: "Available Tours Themes",
        description:
          "To learn more about our tours, please go to <a href='https://www.norton.org/programs/studentsandteachers/k-12home' target='_blank'>School Tour Themes</a>",
        options: [
          {
            label: "Learning to Look (K-12) VA, LA, SS, SC, MA",
            value: "Learning to Look",
          },
          {
            label: "Storytelling in Art (K-12) VA, LA, SS, SC, MA",
            value: "Storytelling in Art",
          },
          { label: "STEAM Tour (K-12) VA, LA, SS, SC, MA", value: "STEAM" },
          {
            label:
              "Special Exhibition: <i>Strike Fast, Dance Lightly</i><br>OCTOBER 28, 2024 – MARCH 4, 2025",
            value: "Special Exhibition, Strike Fast, Dance Lightly",
          },
          {
            label:
              "Special Exhibition: <i>Sorolla and the Sea</i><br>DECEMBER 2, 2024 – APRIL 11, 2025",
            value: "Special Exhibition, Sorolla and the Sea",
          },
        ],
        required: true,
      },
      {
        type: "radio",
        name: "extended_tour",
        label: "Extended Tour",
        description: `Extend your tour with a 50-minute art workshop in the classrooms linked to the tour theme and led by the Norton’s Teaching Artists. (K-12 Grades). 
          <strong>Available only if booking a 10am start time for guided tours. Please note extended tours are NOT offered for Special Exhibitions.</strong>`,
        options: [
          { label: "Yes, add extended tour", value: "1" },
          { label: "No, thanks", value: "0" },
        ],
        required: true,
      },
      {
        type: "text",
        name: "goals",
        label: "Goals of Your Tour",
        description:
          "Please specify what subject/elective your group is along with any curricular connections or what students are learning in the classroom around the time of your tour",
        required: true,
      },
    ],
  ],
};

export const additionalInfo = {
  label: "Additional Information",
  columns: [
    [
      {
        type: "checkbox",
        name: "school_type[]",
        label: "School Type",
        description: "Please select ALL that apply.",
        options: [
          { label: "Public", value: "Public" },
          { label: "Private", value: "Private" },
          { label: "Charter", value: "Charter" },
          { label: "Magnet", value: "Magnet" },
          { label: "Title I", value: "Title I" },
          {
            label:
              "Homeschool (<a href='https://www.norton.org/event-series/homeschool-tours' target=_blank>Learn More</a>)",
            value: "Homeschool",
          },
          { label: "Other", value: "Other" },
        ],
        required: true,
        other: { name: "school_type_other", label: "Other School Type" },
      },
      {
        type: "checkbox",
        name: "classroom_type[]",
        label: "Classroom Type",
        description: "Please select ALL that apply.",
        options: [
          { label: "General Education", value: "General Education" },
          { label: "ELL/ESOL", value: "ELL/ESOL" },
          {
            label: "Special Education/ESE/Gifted",
            value: "Special Education/ESE/Gifted",
          },
          { label: "AP", value: "AP" },
          { label: "ASD (autism spectrum disorder)", value: "ASD" },
          { label: "Other", value: "Other" },
        ],
        required: true,
        other: {
          name: "classroom_type_other",
          label: "Other Classroom Type",
        },
      },
      {
        type: "checkbox",
        name: "special_needs[]",
        label:
          "Special accommodations or requests for students with disabilities",
        description:
          "The Norton Museum of Art provides special accommodations for students with disabilities and trained staff to work with your individualized student needs",
        options: [
          { label: "ASL Interpretation", value: "ASL Interpretation" },
          {
            label: "Handling Objects",
            value: "Handling Objects",
          },
          {
            label: "ASD (autism spectrum disorder)",
            value: "ASD Special Needs",
          },
          {
            label: "No special accommodations or requests for my group ",
            value: "None",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
        required: true,
        other: {
          name: "special_needs_other",
          label: "Please specify what accommodations are needed for your group",
          showLabel: true,
          required: true,
        },
      },
    ],
    [
      {
        type: "radio",
        name: "transportation",
        label: "Transportation",
        description:
          "Free busing for Palm Beach County School District schools only.",
        options: [
          {
            label:
              "Yes, I will request transportation via the PBCSD field trip form to apply for free busing.",
            value: "1",
          },
          {
            label: "No, I will arrange my own transportation.",
            value: "0",
          },
        ],
        required: true,
      },
      {
        type: "radio",
        name: "lunch",
        label: "Lunch Space",
        description:
          "Reserve lunch space in the Sculpture Garden for a picnic style lunch. 30-minute lunch.",
        options: [
          { label: "Yes, reserve lunch space", value: "1" },
          { label: "No, thanks", value: "0" },
        ],
        required: true,
      },
      {
        type: "radio",
        name: "lunch_details",
        label: "Please let us know your lunch arrangements for your trip",
        options: [
          {
            label: "Students will bring school lunch, or packed lunches.",
            value: "Students will bring school lunch, or packed lunches",
          },
          {
            label:
              "Some or all of my students are interested in lunch from The Restaurant. I would like information about lunch from The Restaurant.",
            value: "We are interested in lunch from The Restaurant, send info",
          },
          {
            label: "I will not be having lunch at the Museum.",
            value: "I will not be having lunch at the Museum",
          },
        ],
        required: true,
      },
      {
        type: "text",
        name: "lunch_time",
        label: "What time do you have lunch at school?",
        required: true,
      },
      {
        type: "radio",
        name: "educational_materials",
        label: "Educational Materials",
        description:
          "Educational materials to support guided tours can be provided by Norton staff with advanced notice.",
        options: [
          { label: "Yes, I'm interested", value: "1" },
          { label: "No, thanks", value: "0" },
        ],
        required: true,
      },
    ],
  ],
};
