import React from "react";
import { Text, Date } from "../atomic";
import classNames from "classnames";

export default function Field({
  label,
  name,
  description,
  type,
  required,
  endDate,
  startDate,
  excludeDates,
  excludeWeekdays,
  ...fieldProps
}) {
  const InputComponent = type === "date" ? Date : Text;

  return (
    <div className="m-form__field">
      {label && (
        <label
          htmlFor={name && name}
          className={classNames("m-form__label", {
            "m-form__required": required,
          })}
        >
          {label}
        </label>
      )}
      {description && (
        <p
          className="m-form__field-descript"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      <InputComponent
        name={name}
        required={required}
        type={type}
        endDate={endDate}
        startDate={startDate}
        excludeDates={excludeDates}
        excludeWeekdays={excludeWeekdays}
        {...fieldProps}
      />
    </div>
  );
}
