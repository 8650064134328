import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import DayPicker from "react-day-picker";

export default class CalendarDateNav extends PureComponent {
  static displayName = "Calendar.DateNav";

  static propTypes = {
    handleDayClick: PropTypes.func,
    selectedDay: PropTypes.object,
    canAccessDate: PropTypes.func.isRequired,
    visibility: PropTypes.bool,
    getHeight: PropTypes.func,
  };

  refCallback = (element) => {
    this.props.getHeight(element.getBoundingClientRect().height);
  };

  Weekday({ weekday, className, localeUtils, locale }) {
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
      <div className={className} title={weekdayName}>
        {weekdayName === "Thursday"
          ? weekdayName.slice(0, 2)
          : weekdayName.slice(0, 1)}
      </div>
    );
  }

  disabledDay = (day) => {
    return !this.props.canAccessDate(day);
  };

  Navbar({
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className,
    localeUtils,
  }) {
    const months = localeUtils.getMonths();
    const prev = months[previousMonth.getMonth()];
    const next = months[nextMonth.getMonth()];
    const styleLeft = {
      float: "left",
    };
    const styleRight = {
      float: "right",
    };
    return (
      <div className={className}>
        <button
          style={styleLeft}
          onClick={() => {
            onPreviousClick();
          }}
        >
          ← {prev.slice(0, 3)}
        </button>
        <button
          style={styleRight}
          onClick={() => {
            onNextClick();
          }}
        >
          {next.slice(0, 3)} →
        </button>
      </div>
    );
  }

  render() {
    const containerClasses = classNames("day-picker", {
      visible: this.props.visibility,
      hidden: !this.props.visibility,
    });
    const modifiers = { closed: [new Date(2018, 6, 1), new Date(2018, 6, 3)] };

    return (
      <div ref={this.refCallback} className={containerClasses}>
        <DayPicker
          weekdayElement={<this.Weekday />}
          onDayClick={this.props.handleDayClick}
          selectedDays={this.props.selectedDay}
          disabledDays={this.disabledDay}
          // navbarElement={<this.Navbar />}
          modifiers={modifiers}
        />
      </div>
    );
  }
}
