import React from "react";
import classNames from "classnames";

export default function FormSelectCard({ className, handleTourType, intro }) {
  return (
    <div
      className={classNames(
        "m-form__card--not-fieldset m-form__card--dark content-default",
        className
      )}
    >
      <h4>About school tours</h4>
      {intro && <div dangerouslySetInnerHTML={{ __html: intro }} />}
      <div role="radiogroup" className="m-form__field heading-tertiary">
        <span className="m-form__label">
          Which kind of tour are you booking?
        </span>
        <div className="m-form__radio-wrapper">
          <input
            id="guided"
            type="radio"
            name="type"
            className="m-form__radio--lg"
            value="guided_tour"
            onChange={(e) => handleTourType(e.currentTarget.value)}
          />
          <label htmlFor="guided">Guided Tour (In-person)</label>
        </div>
        <div className="m-form__radio-wrapper">
          <input
            id="virtual"
            type="radio"
            name="type"
            className="m-form__radio--lg"
            value="virtual_tour"
            onChange={(e) => handleTourType(e.currentTarget.value)}
          />
          <label htmlFor="virtual">Virtual Tour</label>
        </div>
        <div className="m-form__radio-wrapper">
          <input
            id="self-guided"
            type="radio"
            name="type"
            className="m-form__radio--lg"
            value="self_guided_tour"
            onChange={(e) => handleTourType(e.currentTarget.value)}
          />
          <label htmlFor="self-guided">Teacher-Led Tour</label>
        </div>
      </div>
    </div>
  );
}
