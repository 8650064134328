import debounce from "lodash/debounce";
import Sb from "../abstract/StatefulBehavior";

export default class AlignSubmenu extends Sb {
  constructor(el, props, refs) {
    super();

    const defaultProps = {
      direction: "left",
    };

    this.refs = refs;
    this.props = Object.assign(defaultProps, props);

    // Set initial state
    this.state = Object.assign({}, this.getTitlePosition());

    // Calculate once on load
    this.update();

    // Then again on resize
    this.bindResize();
  }

  update = () => {
    const menu = this.refs.menu;
    if (this.props.direction === "left") {
      menu.style.marginLeft = this.state.left + "px";
    }
  };

  getTitlePosition() {
    // Get left position of item
    const activator = this.refs.activator;
    const box = activator.getBoundingClientRect();
    const activatorStyle = window.getComputedStyle(activator);
    const menuStyle = window.getComputedStyle(this.refs.menu);
    const left =
      box.left +
      parseInt(activatorStyle.paddingLeft, 10) -
      parseInt(menuStyle.paddingLeft, 10);

    return { left };
  }

  bindResize() {
    const debouncedAlign = debounce(() => {
      this.setState(Object.assign({}, this.getTitlePosition()));
    }, 200);

    window.addEventListener("resize", debouncedAlign);
  }
}
