import React from "react";
import { RadioGroup, CheckboxGroup } from "../groups";
import Field from "./Field";

const FIELD_MAP = {
  text: Field,
  email: Field,
  tel: Field,
  date: Field,
  radio: RadioGroup,
  checkbox: CheckboxGroup,
};

export default function FieldColumn({
  fields,
  endDate,
  startDate,
  excludedDates,
}) {
  return fields.length ? (
    <div className="m-form__fieldgroup">
      {fields.map((field) => {
        const FieldComponent = FIELD_MAP[field.type];
        const { name, label, ...fieldProps } = field;
        const dateProps =
          field.type === "date" ? { endDate, startDate, excludedDates } : {};

        return (
          <FieldComponent
            key={name}
            name={name}
            label={label}
            {...dateProps}
            {...fieldProps}
          />
        );
      })}
    </div>
  ) : null;
}
