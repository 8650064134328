import Sb from "../abstract/StatefulBehavior";
import anime from "animejs";
import ScrollLock from "cic-scroll-lock";
import Cookies from "js-cookie";

/*
ShrinkHero triggers a hero to contract from fullscreen
to fit into its container based on one of the following:
1) A user's scroll events are captured and used to trigger the animation
2) Clicking the scrollTrigger ref shrinks the hero while scrolling below
3) After 10s, the hero shrinks on its own

The animation can only occur once. After, scroll locking is released and page
interaction is normal. We also set a cookie so that the animation doesn't occur
every visit.

This class also sets the styles for the expanded hero (before shrinking them),
so users that don't have JavaScript will just see the normal hero.
*/
export default class ShrinkHero extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      expanded: true,
      imageLoaded: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.src = "";

    // always allow scrollTrigger
    this.bindScrollTrigger();
    // load background image from srcset (unless cached) and proceed
    refs.image.complete
      ? this.setState({ imageLoaded: true }, this.maybePrepareAnimation())
      : this.loadImage();
  }

  loadImage = () => {
    this.refs.image.addEventListener("load", (e) => {
      this.setState({ imageLoaded: true }, this.maybePrepareAnimation());
    });
  };

  maybePrepareAnimation = () => {
    this.renderBackgroundImage();

    // Check conditions for doing the animation
    // 1) make sure user is at top of page before setting up animation
    this.checkScrollPosition();

    // 2) if shrinking is off, don't worry about the cookies
    // otherwise, make sure this is the user's first visit
    if (this.props.shrink === "false") {
      this.setState({ expanded: false });
    } else {
      this.checkCookie();
    }

    // exit early if conditions aren't right
    if (!this.state.expanded) return;
    // otherwise, setup animation and scroll lock
    this.scrollSum = 0;
    this.animationTimer = null;
    this.animation = anime.timeline({
      duration: 1500,
      easing: "easeOutSine",
      autoplay: false,
      complete: (anim) => {
        this.resetHero();
        this.unlockScroll();
      },
    });
    this.setupAnime();
    this.setupScrollLock();
    this.expire();
  };

  renderBackgroundImage = () => {
    const { image, background } = this.refs;
    const src =
      typeof image.currentSrc !== "undefined" ? image.currentSrc : image.src;
    if (this.src !== src) {
      this.src = src;
      background.style.backgroundImage = 'url("' + this.src + '")';
      this.el.classList.add("loaded");
    }
  };

  checkScrollPosition() {
    window.scrollY > 0 && this.setState({ expanded: false });
  }

  checkCookie() {
    const cookie = Cookies.get("viewedHeroAnimation");
    // if no cookie exists, create one
    if (typeof cookie === "undefined") {
      Cookies.set("viewedHeroAnimation", "true", { expires: 0.5, path: "" });
    } else if (cookie === "true") {
      this.setState({ expanded: false });
    }
  }

  setupAnime = () => {
    const el = this.el;
    const { logo, heading, background } = this.refs;
    const elOffset = el.getBoundingClientRect();
    const clientWidth = document.body.clientWidth;
    // shrink width equal to how much height will shrink from bottom (in CSS)
    // 72px for 980+ wide viewports, 3.41vw for smaller screens
    const addedWidth =
      clientWidth > 980 ? 72 : (clientWidth - el.offsetWidth) / 2;

    // set presentation of expanded hero
    Object.assign(el.style, { zIndex: 100 });
    Object.assign(logo.style, { opacity: 1 });
    Object.assign(heading.style, { opacity: 0 });

    // build animation timeline
    this.animation
      .add({
        targets: background,
        width: [el.offsetWidth + addedWidth * 2, el.offsetWidth],
        height: [window.innerHeight, el.offsetHeight],
        left: [-addedWidth, 0],
        top: [-elOffset.top, 0],
        offset: 250,
      })
      .add({
        targets: logo,
        opacity: 0,
        offset: 250,
      })
      .add({
        targets: heading,
        opacity: 1,
        offset: 250,
      });
  };

  setupScrollLock = () => {
    // if hero already shrunk by other method, don't do anything
    if (!this.state.expanded) return;

    this.scrollLock = new ScrollLock();
    // lock scrolling, only fullscreen background can receive scroll events
    this.scrollLock.only(this.refs.scrollCapture);

    // modify handleEventDelta method for this instance
    // to count up scroll deltas and seek on animation timeline as a side effect
    this.scrollLock.handleEventDelta = (e, delta) => {
      const self = this.scrollLock;
      const isDeltaPositive = delta > 0;
      const elem = self.scrollingElement;
      const { scrollTop, scrollHeight, clientHeight } = elem;

      let shouldCancelScroll = false;
      if (isDeltaPositive && delta > scrollHeight - clientHeight - scrollTop) {
        elem.scrollTop = scrollHeight;
        shouldCancelScroll = true;
      } else if (!isDeltaPositive && -delta > scrollTop) {
        elem.scrollTop = 0;
        shouldCancelScroll = true;
      }

      if (shouldCancelScroll) {
        self.cancelScrollEvent(e);
      }

      // pass delta to another method to animate hero on scroll
      this.animateOnScroll(delta);
    };
  };

  expire = () => {
    this.animationTimer = setTimeout(() => {
      this.state.expanded &&
        this.setState({ expanded: false }, this.playAnimation);
    }, 10000);
  };

  bindScrollTrigger() {
    const trigger = this.refs.scrollTrigger;
    trigger.addEventListener("click", this.scrollBelow);
  }

  animateOnScroll(delta) {
    if (delta > 0) {
      this.setState({ expanded: false }, this.playAnimation);
    }
  }

  scrollBelow = () => {
    const trigger = this.refs.scrollTrigger;
    const box = this.el.getBoundingClientRect();

    if (window.pageYOffset < box.bottom) {
      anime({
        targets: "html, body",
        scrollTop: box.bottom,
        duration: box.bottom * 1.5,
        easing: "easeInOutCubic",
        begin: (anim) => {
          this.state.expanded &&
            this.setState({ expanded: false }, this.playAnimation);
        },
      });
    }
  };

  playAnimation = () => {
    if (!this.state.expanded) {
      this.animation.play();
    }
  };

  unlockScroll() {
    this.scrollLock.any(this.refs.scrollCapture);
  }

  resetHero() {
    Object.assign(this.el.style, { zIndex: "50" });
    Object.assign(this.refs.background.style, {
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    });
  }
}
