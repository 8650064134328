import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";

export default class VideoPlayerOverlay extends PureComponent {
  static displayName = "Content.VideoPlayer.Overlay";

  static propTypes = {
    videoId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  get playerOptions() {
    return {
      height: "315",
      width: "560",
    };
  }

  handleKeyUp = (event) => {
    let isEsc = false;

    if ("key" in event) {
      isEsc = event.key === "Escape" || event.key === "Esc";
    } else {
      isEsc = event.keyCode === 27 || event.which === 27;
    }

    if (isEsc) {
      this.handleCloseClick();
    }
  };

  handleCloseClick = () => {
    this.props.onClose();
  };

  render() {
    return (
      <div className="overlay react visible">
        <div className="container content-section">
          <button className="close" onClick={this.handleCloseClick}></button>
          <div className="video-player">
            <button className="mobile-close" onClick={this.handleCloseClick}>
              BACK
            </button>
            <YouTube videoId={this.props.videoId} opts={this.playerOptions} />
          </div>
        </div>
      </div>
    );
  }
}
