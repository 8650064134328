import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Flickity from "flickity";
import anime from "animejs";

import DetailSlide from "./DetailSlide";

export default class Slideshow extends PureComponent {
  // NB: This could be abstracted as SlideshowOverlay (or adjusted with props),
  // but currently this is the only type
  static displayName = "Content.Slideshow";

  static propTypes = {
    slides: PropTypes.array,
    contentType: PropTypes.string,
    heading: PropTypes.string,
    active: PropTypes.number,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor() {
    super();

    // Prevent Flickity from jumping on focus
    // https://github.com/metafizzy/flickity/issues/325
    const touchStartEvents = {
      touchstart: true,
      MSPointerDown: true,
    };

    const focusNodes = {
      INPUT: true,
      SELECT: true,
    };

    Flickity.prototype.pointerDownFocus = function (event) {
      // focus element, if not touch, and its not an input or select
      if (
        !this.options.accessibility ||
        touchStartEvents[event.type] ||
        focusNodes[event.target.nodeName]
      ) {
        return;
      }
      // hack to fix scroll jump after focus, #76
      const scrollElem = this.options.scrollElement || window;
      const scrollProp = this.options.scrollElement
        ? "scrollTop"
        : "pageYOffset";
      const prevScrollY = scrollElem[scrollProp];

      this.element.focus();
      // reset scroll position after focus
      if (scrollElem[scrollProp] !== prevScrollY) {
        if (this.options.scrollElement) {
          scrollElem.scrollTop = prevScrollY;
        } else {
          scrollElem.scrollTo(scrollElem.pageXOffset, prevScrollY);
        }
      }
    };
  }

  componentDidMount() {
    this.imageSlider = new Flickity(this.images, {
      lazyLoad: 2,
      initialIndex: this.props.active,
      prevNextButtons: false,
      pageDots: false,
      adaptiveHeight: true,
      scrollElement: this.refs.slideshow,
    });

    this.imageSlider.focus();

    // Update parent state when slider changes
    this.imageSlider.on("change", this.updateActive);
    this.imageSlider.on("staticClick", this.clickActive);

    if (this.props.contentType === "artwork") {
      this.detailSlider = new Flickity(this.details, {
        initialIndex: this.props.active,
        prevNextButtons: false,
        pageDots: false,
        scrollElement: this.refs.slideshow,
        dragThreshold: 20,
      });

      this.detailSlider.on("change", this.updateActive);
    }

    this.addEscListener();
  }

  componentDidUpdate(prevProps) {
    // update one slider from the other (if multiple)
    if (this.detailSlider && this.props.active !== prevProps.active) {
      this.imageSlider.select(this.props.active);
      this.detailSlider.select(this.props.active);
      const scrollToTop = anime({
        targets: this.refs.slideshow,
        scrollTop: this.refs.slideshow.top,
        duration: 375,
        delay: 375,
        easing: "easeInOutCubic",
      });
    }
  }

  updateActive = (active) => {
    if (this.props.active !== active) {
      this.props.onUpdate(active);
    }
  };

  clickActive = (event, pointer, cellElement, cellIndex) => {
    this.imageSlider.select(cellIndex);
  };

  addEscListener() {
    window.addEventListener("keyup", (e) => {
      let isEsc = false;

      if ("key" in e) {
        isEsc = e.key === "Escape" || e.key === "Esc";
      } else {
        isEsc = e.keyCode === 27 || e.which === 27;
      }

      if (isEsc) {
        this.props.onClose();
      }
    });
  }

  renderImageSlider(slides, contentType) {
    return (
      <ul
        className="images"
        ref={(s) => {
          this.images = s;
        }}
      >
        {slides.map((slide, index) => {
          return (
            <li className="slide" key={index}>
              <figure>
                <img data-flickity-lazyload={slide.image} alt="" />
              </figure>
              {contentType === "callout" && slide.caption ? (
                <span className="figcaption">{slide.caption}</span>
              ) : null}
            </li>
          );
        })}
      </ul>
    );
  }

  renderDetailSlider(slides) {
    return (
      <ul
        className="details"
        ref={(d) => {
          this.details = d;
        }}
      >
        {slides.map((slide, index) => {
          return (
            <li className="detail artwork-detail container" key={index}>
              <DetailSlide data={slide} />
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { slides, contentType, active, heading } = this.props;
    const headerClass = classNames("container header", {
      title: heading,
    });

    return (
      <div ref="slideshow" className="slideshow">
        <button className="mobile-close" onClick={this.props.onClose}>
          BACK
        </button>
        <div className="sliders">
          <header className={headerClass}>
            <div className="wrapper">
              <div className="page-count">
                <span className="ordinal">{active + 1}</span>
                {" of "}
                <span className="ordinal">{slides.length}</span>
              </div>
              {heading && <h3>{heading}</h3>}
            </div>
            <button className="close" onClick={this.props.onClose} />
          </header>
          {this.renderImageSlider(slides, contentType)}
          {contentType === "artwork" ? this.renderDetailSlider(slides) : null}
        </div>
      </div>
    );
  }
}
