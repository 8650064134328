import pageApiRequest from "api/Page";
import classNames from "classnames";
import anime from "animejs";

import AnimateLoading from "behaviors/site/AnimateLoading";
import Percolator from "helpers/Percolator";
import behaviors from "behaviors";
import components from "components";

export default class LoadPageContent {
  constructor(el, props, refs) {
    this.state = {
      activeRequests: [],
      loading: false,
      currentApiPath: null,
      // active: props.active ? props.active : 0
    };

    // Setup required class variables
    this.nav = refs.nav;
    this.contentWrapper = refs.contentWrapper;
    this.heroWrapper = refs.heroWrapper;
    this.wrapperInitClass = this.contentWrapper.className;
    // Instantiate loading animation
    this.loader = new AnimateLoading(refs.loader);

    this.initHistory();

    this.handleInternal = this.handleInternal.bind(this);

    this.handlePopState = this.handlePopState.bind(this);

    // Bind links to ajax fetch (if nav exists)
    this.addListeners();
  }

  addListeners() {
    this.addLinkListeners();
    this.addWindowListeners();
  }

  handleLinkClick = (event) => {
    event.preventDefault();
    const href = event.target.getAttribute("href");
    const apiPath = this.hrefToApi(href);
    this.load(apiPath);
  };

  hrefToApi(href) {
    return `/content${href}`;
  }

  apiToHref(api) {
    return api.substring("/content".length);
  }

  addLinkListeners() {
    if (!this.nav) return;
    const cb = (link) => {
      if (link.dataset.canFetchContent === "false") return;
      link.addEventListener("click", this.handleLinkClick);
    };
    this.linkElements.forEach(cb);
  }

  addWindowListeners() {
    // Bind popstate to track back button
    window.addEventListener("popstate", this.handlePopState);
    window.addEventListener("ajaxpageload", this.handleInternal);
  }

  initHistory() {
    // Replace current history with a state object for
    // back button returns to initial place
    const url = this.currentUrl;
    const newState = { url };
    window.history.replaceState(newState, null, url);
  }

  get currentUrl() {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }

  get linkElements() {
    return [...this.nav.querySelectorAll("a")];
  }

  get activeNavElements() {
    const url = this.apiToHref(this.state.currentApiPath);
    const matches = this.linkElements.filter((linkEl) => {
      return url.split("?")[0] === linkEl.getAttribute("href").split("?")[0];
    });

    return matches.map((match) =>
      match.parentElement.nodeName === "LI" ? match.parentElement : null
    );
  }

  loadable(apiPath) {
    if (this.requestedPathIsCurrentPath(apiPath)) return false;
    return true;
  }

  requestedPathIsCurrentPath(apiPath) {
    return apiPath === this.state.currentApiPath;
  }

  updateCurrentApiPath(apiPath) {
    this.state.currentApiPath = apiPath;
  }

  startLoader() {
    this.loader.loading();
    this.contentWrapper.className = classNames(
      this.wrapperInitClass,
      "loading"
    );
  }

  stopLoader() {
    this.loader.loaded();
    this.contentWrapper.className = classNames(this.wrapperInitClass, "loaded");
  }

  maybeStartLoader = () => {
    if (this.state.activeRequests.length > 0) this.startLoader();
  };

  maybeStopLoader = () => {
    if (this.state.activeRequests.length === 0) this.stopLoader();
  };

  doApiRequest(apiPath, onSuccess, onError) {
    return pageApiRequest(apiPath, onSuccess, onError);
  }

  removeActiveRequest(requestPromise) {
    let remove = null;
    this.state.activeRequests.forEach((anActiveRequest, index) => {
      if (anActiveRequest === requestPromise) {
        remove = index;
      }
    });
    if (remove !== null) {
      const removed = this.state.activeRequests.splice(remove, 1);
    }
  }

  clearActiveRequests() {
    this.state.activeRequests = [];
  }

  handleLoadError(error) {
    const contentWrapper = document.querySelector(
      '[data-ref="contentWrapper"]'
    );
    const errorMarkup = `
    <div class="container content-section align-center">
      <div class="container-primary content-default">
        <header>
          <h2>There was an error loading this page.</h2>
          <p>
            Something went awry while loading this page.
            Please check your network connection,
            then try reloading the page.
          </p>
        </header>
      </div>
    </div>
  `;

    contentWrapper.innerHTML = errorMarkup;
    this.clearActiveRequests();
    this.maybeStopLoader();
    this.loadBehaviorsAndComponents();
    /* eslint-disable no-console */
    console.log(error);
    /* eslint-enable no-console */
  }

  load(apiPath, updateLocation = true) {
    if (!this.loadable(apiPath)) return;
    this.updateCurrentApiPath(apiPath);

    const onSuccess = (response) => {
      if (!response.ok) throw Error(response);
      this.handleLoadSuccess(requestPromise, response, updateLocation);
    };

    const onError = (error) => {
      this.handleLoadError(error);
    };

    const requestPromise = this.doApiRequest(apiPath, onSuccess, onError)
      .catch((error) => {
        onError(error);
      })
      .then(this.maybeStopLoader, this.maybeStopLoader);
    this.state.activeRequests.push(requestPromise);
    this.maybeStartLoader();
  }

  handleLoadSuccess = (requestPromise, response, updateLocation) => {
    this.removeActiveRequest(requestPromise);
    response.json().then(
      (responseData) => {
        this.renderNewContent(responseData);
        this.updateActiveNavItem();
        this.loadBehaviorsAndComponents();
        if (updateLocation) this.updateLocation();
        this.scrollToContent();
      },
      (error) => {
        /* eslint-disable no-console */
        console.log(error, "Unable to parse response json data");
        /* eslint-enable no-console */
      }
    );
  };

  updateActiveNavItem() {
    if (!this.nav) return;

    const activeEls = this.activeNavElements;
    this.linkElements.forEach((el) => {
      const parentEl = el.parentElement;
      if (parentEl.nodeName === "LI" && activeEls.includes(parentEl)) {
        parentEl.classList.add("active");
      } else {
        parentEl.classList.remove("active");
      }
    });
  }

  updateLocation() {
    const url = this.apiToHref(this.state.currentApiPath);
    window.history.pushState({ url }, null, url);
  }

  loadBehaviorsAndComponents() {
    // Rebind behaviors/components when content is loaded
    const percolator = new Percolator();
    percolator.loadBehaviors(behaviors.content, this.contentWrapper);
    percolator.loadBehaviors(behaviors.content, this.heroWrapper);
    percolator.loadComponents(components.content, this.contentWrapper);
  }

  renderNewContent(responseData) {
    this.contentWrapper.innerHTML = responseData.content;
    this.heroWrapper.innerHTML = responseData.hero;
  }

  scrollToContent() {
    if (!this.nav) return;
    // Get bounding box for nav, and then scroll to
    // it if that hasn't happened yet
    const box = this.nav.getBoundingClientRect();
    if (box.top > 0) {
      anime({
        targets: "html, body",
        scrollTop: [window.pageYOffset, "+=" + box.top],
        duration: box.top * 1.5,
        easing: "easeInOutCubic",
      });
    }
  }

  handlePopState(event) {
    const current = event.state;
    if (current.url !== undefined) {
      const apiPath = this.hrefToApi(current.url);
      this.load(apiPath, false);
    }
  }

  // Handle page navigation events from inside of content
  handleInternal(event) {
    if (event.detail.href) {
      this.load(this.hrefToApi(event.detail.href));
    }
  }
}
