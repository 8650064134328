import Percolator from "../../helpers/Percolator";
import RevealSubmenu from "./RevealSubmenu";

export default class ControlSubmenus {
  constructor(el, props, refs) {
    const percolator = new Percolator();

    // Instantiate (and track) the reveals
    // with a callback that runs the "close"
    // function on all but the active hover
    this.reveals = refs.reveal.map((reveal, index) => {
      return percolator.loadBehavior(reveal, RevealSubmenu, {
        id: index,
        onHover: this.closeAll,
      });
    });
  }

  closeAll = (active) => {
    this.reveals.forEach((reveal, index) => {
      if (index !== active) {
        reveal.close();
      }
    });
  };
}
