import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import ScrollLock from "cic-scroll-lock";

import Overlay from "./Overlay";

export default class VideoPlayerWrapper extends PureComponent {
  static displayName = "Content.VideoPlayer.Wrapper";

  static propTypes = {
    videoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  };

  constructor(props) {
    super(props);
    this.scrollLock = new ScrollLock();

    this.state = {
      open: false,
    };
  }

  openOverlay = () => {
    this.disableScroll();
    this.setState({ open: true });
  };

  closeOverlay = () => {
    this.enableScroll();
    this.setState({ open: false });
  };

  disableScroll = () => {
    const overlayEl = ReactDOM.findDOMNode(this);
    document.body.classList.add("no-scroll");
    this.scrollLock.only(overlayEl);
  };

  enableScroll = () => {
    const overlayEl = ReactDOM.findDOMNode(this);
    document.body.classList.remove("no-scroll");
    this.scrollLock.any(overlayEl);
  };

  render() {
    return (
      <div className="teaser-body">
        <div
          role="button"
          tabIndex="0"
          className="teaser"
          onClick={this.openOverlay}
        >
          {this.props.children}
        </div>
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={100}
          transitionLeaveTimeout={350}
        >
          {this.state.open && (
            <Overlay videoId={this.props.videoId} onClose={this.closeOverlay} />
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}
