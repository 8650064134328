import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";

// adapted from
// https://aclaes.com/responsive-background-images-with-srcset-and-sizes/
export default class ResponsiveBackgroundImage extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      loaded: false,
      mobile: this.isMobile,
    };

    this.el = el;
    this.refs = refs;
    this.src = "";

    this.bindResize();

    // Update state immediately if image is cached
    // otherwise listen for image load
    this.imageEl.complete ? this.setState({ loaded: true }) : this.loadImage();
  }

  update = () => {
    if (!this.state.loaded) return;
    const src =
      typeof this.imageEl.currentSrc !== "undefined"
        ? this.imageEl.currentSrc
        : this.imageEl.src;

    if (this.src !== src) {
      this.src = src;
      this.el.style.backgroundImage = 'url("' + this.src + '")';
      this.el.classList.add("loaded");
    }
  };

  get isMobile() {
    return document.documentElement.clientWidth < 760;
  }

  get imageEl() {
    if (this.isMobile && this.refs.mobileImage) {
      return this.refs.mobileImage;
    } else {
      return this.refs.image;
    }
  }

  bindResize() {
    const debouncedClientSize = debounce(() => {
      this.setState({
        mobile: this.isMobile,
      });
    }, 200);

    window.addEventListener("resize", debouncedClientSize);
  }

  loadImage = () => {
    this.imageEl.addEventListener("load", (e) => {
      this.setState({ loaded: true });
    });
  };
}
