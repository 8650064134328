import React, { Component } from "react";
import PropTypes from "prop-types";
import types from "api/types";
import VideoPlayer from "../Wrapper";
import Text from "../Text";

export default class VideoGrid extends Component {
  static displayName = "Content.Video.Grid";

  static propTypes = {
    videos: PropTypes.arrayOf(types.videoType),
  };

  render() {
    if (this.props.videos.length <= 0) {
      return (
        <h3 className="video-teaser-grid__no-content-message">
          There are no videos here
        </h3>
      );
    }

    return (
      <div className="video-teaser-grid">
        <div className="columns-3">
          <div className="grid-primary">
            {this.props.videos.map((video) => (
              <div key={video.youtube_id + video.id} className="grid-item">
                <VideoPlayer videoId={video.youtube_id}>
                  <figure
                    style={{
                      backgroundImage: `url('${video.video_image}')`,
                    }}
                  />
                  <Text
                    parentClassName="video-teaser-grid"
                    description={video.description}
                    title={video.title}
                  />
                </VideoPlayer>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
