import React from "react";
import { useUID } from "react-uid";

export default function Checkbox({
  disabled,
  required,
  label,
  name,
  value,
  ...inputProps
}) {
  const uid = useUID();

  return (
    <div className="m-form__checkbox-wrapper">
      <input
        id={`${uid}_${value}`}
        name={name}
        value={value}
        type="checkbox"
        className="m-form__checkbox"
        aria-disabled={disabled}
        required={required}
        {...inputProps}
      />
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={`${uid}_${value}`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </div>
  );
}
