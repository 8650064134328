import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import Caption from "./Caption";

export default class CalloutSecondaryCaptions extends PureComponent {
  static displayName = "Content.ImageCallout.SecondaryCaptions";

  static propTypes = {
    slides: PropTypes.array,
    active: PropTypes.number,
  };

  renderCaption(slide, index, length) {
    const caption = {
      label: slide.label,
      heading: slide.secondary_heading,
      body: slide.secondary_body,
      url: slide.url,
      linkText: slide.link_text,
    };

    return (
      <Caption
        key={index}
        data={caption}
        width={length ? `${100 / length}%` : null}
      />
    );
  }

  render() {
    const { slides, active } = this.props;

    if (slides.length === 0) return null;

    return (
      <div>
        <ReactCSSTransitionGroup
          transitionName="caption-fade"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
          className="fading"
        >
          {this.renderCaption(slides[active], active)}
        </ReactCSSTransitionGroup>
        <div
          className="placeholders"
          style={{
            width: `${100 * slides.length}%`,
          }}
        >
          {slides.map((slide, index) => {
            return this.renderCaption(slide, index, slides.length);
          })}
        </div>
      </div>
    );
  }
}
