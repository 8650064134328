import Sb from "../abstract/StatefulBehavior";

export default class OpenImageGridSlideshow extends Sb {
  static behaviorName = "OpenImageGridSlideshow";

  constructor(el, props, refs) {
    super();
    this.el = el;
    this.props = props;
    this.refs = refs;
    this.registerElementOffset();
    this.addListener();
  }

  registerElementOffset = () => {
    const elOffset = this.el.getBoundingClientRect().top;

    this.props.dispatch({
      type: "REGISTER_ELEMENT_OFFSET",
      imageOffsets: {
        id: this.props.id,
        YOffset: elOffset,
      },
    });
  };

  addListener = () => {
    this.el.addEventListener("click", this.doToggle);
  };

  doToggle = (event) => {
    event.preventDefault();
    this.props.dispatch({
      type: "OPEN_SLIDESHOW",
      current: this.props.id,
    });
  };
}
