export default class PaginateAsync {
  constructor(el, props, refs) {
    const links = el.querySelectorAll("a");

    links.forEach((link) => {
      this.bindLink(link);
    });
  }

  bindLink(link) {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-undef
      const loadEvent = new CustomEvent("ajaxpageload", {
        detail: {
          href: link.getAttribute("href"),
        },
      });

      window.dispatchEvent(loadEvent);
    });
  }
}
