import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class MiniCalendarItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (window && window.addeventatc) window.addeventatc.refresh();
  }

  get item() {
    return this.props.item;
  }

  getSubtypeLabel(subtype) {
    switch (subtype) {
      case "virtual":
        return "Virtual";
      case "video_premiere":
        return "Video Premiere";
      default:
        return "";
    }
  }

  renderCalendarButton() {
    const { name, teaser, start, end } = this.item;

    if (!start?.date) return null;

    return (
      <p className="add-button-wrapper">
        <button title="Add to Calendar" className="addeventatc">
          <span className="addeventatc__button-text">Add to Calendar</span>
          <span className="start">{start.date}</span>
          <span className="end">{end.date}</span>
          <span className="timezone">{start.timezone}</span>
          <span className="title">{name}</span>
          {teaser && <span className="description">{teaser}</span>}
          <span className="location">Norton Museum of Art</span>
        </button>
      </p>
    );
  }

  renderItemType() {
    const { type, subtype } = this.item;

    if (!type && !subtype) return null;

    return (
      <p className="type">
        {subtype && (
          <span className="subtype">{this.getSubtypeLabel(subtype)}</span>
        )}
        {type && <span>{type}</span>}
      </p>
    );
  }

  renderItemName() {
    const { url, name, do_not_link: doNotLink } = this.item;
    if (url && url !== "" && !doNotLink) {
      return (
        <p className="name">
          <a href={url} className="name-link">
            {name}
          </a>
        </p>
      );
    }

    return <p className="name">{name}</p>;
  }

  render() {
    return (
      <tr>
        <td>
          <p>{this.item.time}</p>
          {this.renderCalendarButton()}
        </td>
        <td>
          {this.renderItemType()}
          {this.renderItemName()}
        </td>
      </tr>
    );
  }
}
