import Percolator from "../../helpers/Percolator";
import Flickity from "flickity";
import ObjectFit from "./ObjectFit";

/*
------- SlideCards -------
A Flickity class that creates an image slideshow
*/

export default class SlideCards {
  constructor(el, props, refs) {
    const percolator = new Percolator();

    // Setup equal heights for flickity
    Flickity.prototype._createResizeClass = function () {
      this.element.classList.add("flickity-resize");
    };

    Flickity.createMethods.push("_createResizeClass");

    const resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function () {
      this.element.classList.remove("flickity-resize");
      resize.call(this);
      this.element.classList.add("flickity-resize");
    };

    const flickityOptions = {
      groupCells: true,
      cellAlign: "left",
      ...props,
    };

    // optionally instantiate ObjectFit behavior on slides
    // ensures behavior has run prior to Flickity instantiation so that
    // Flickity's inline styles on slides aren't overridden
    if (props.objectfit) {
      this.images = [...refs.image].map((image, index) => {
        return percolator.loadBehavior(image, ObjectFit);
      });
    }

    const slideWrapper = refs.slides || el;

    const slider = new Flickity(slideWrapper, flickityOptions);
  }
}
