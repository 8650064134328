import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import Content from "./Content";

export default class MiniCalendar extends PureComponent {
  static displayName = "MiniCalendar.Wrapper";

  static propTypes = {
    tabs: PropTypes.array,
  };

  constructor(props) {
    super();

    this.content = React.createRef();

    this.state = {
      active: 0,
      contentHeight: "auto",
    };
  }

  setActive(active) {
    this.setState({ active });
  }

  paginatePrev = () => {
    if (this.state.active > 0) {
      const updatedState = Object.assign({}, this.state, {
        active: this.state.active - 1,
      });
      this.setActive(updatedState.active);
    }
  };

  paginateNext = () => {
    const { tabs } = this.props;
    if (this.state.active < tabs.length - 1) {
      const updatedState = Object.assign({}, this.state, {
        active: this.state.active + 1,
      });
      this.setActive(updatedState.active);
    }
  };

  updateHeight = (contentHeight) => {
    this.setState({ contentHeight });
  };

  renderNavItem(tab, index) {
    return (
      <li key={index} className={index === this.state.active ? "active" : null}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            this.setActive(index);
          }}
        >
          {tab.label}
        </a>
      </li>
    );
  }

  renderMobileNavList() {
    const { tabs } = this.props;
    const prevClasses = classNames("prev", {
      inactive: this.state.active === 0,
    });
    const nextClasses = classNames("next", {
      inactive: this.state.active === tabs.length - 1,
    });

    return (
      <nav className="date-nav mobile">
        <ul>
          <li>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <ReactCSSTransitionGroup
                transitionName="fade"
                transitionEnterTimeout={200}
                transitionLeaveTimeout={200}
              >
                <span key={this.state.active}>
                  {tabs[this.state.active].label}
                </span>
              </ReactCSSTransitionGroup>
            </a>
          </li>
        </ul>
        <button className={prevClasses} onClick={this.paginatePrev}></button>
        <button className={nextClasses} onClick={this.paginateNext}></button>
      </nav>
    );
  }

  renderNavList() {
    const { tabs } = this.props;

    return (
      <nav className="date-nav desktop">
        <ul>
          {tabs.map((tab, index) => {
            return this.renderNavItem(tab, index);
          })}
        </ul>
        <div className="cta">
          <a href="/visit/calendar">See Full Calendar</a>
        </div>
      </nav>
    );
  }

  renderPanels() {
    const { tabs } = this.props;

    return (
      <div className="panels overflow">
        <ReactCSSTransitionGroup
          component="div"
          transitionName="fade"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
        >
          <Content
            key={this.state.active}
            days={tabs[this.state.active].days}
            handleHeight={this.updateHeight}
          />
        </ReactCSSTransitionGroup>
      </div>
    );
  }

  render() {
    return (
      <div
        className="mini-calendar container-wide"
        style={{ height: this.state.contentHeight }}
      >
        <div className="tab-content horizontal">
          {this.renderNavList()}
          {this.renderMobileNavList()}
          {this.renderPanels()}
        </div>
      </div>
    );
  }
}
