import React from "react";
import PropTypes from "prop-types";

Text.propTypes = {
  parentClassName: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default function Text(props) {
  const { title, description } = props;

  if (!title && !description) {
    return null;
  }

  return (
    <div className={`${props.parentClassName}__description`}>
      <h4 className={`${props.parentClassName}__title`}>{title}</h4>
      {description && (
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className={`${props.parentClassName}__body content-rte`}
        />
      )}
    </div>
  );
}
