import React, { useState } from "react";
import FieldsCard from "../cards/FieldsCard";
import FormSelectCard from "../cards/FormSelectCard";
import SubmitCard from "../cards/SubmitCard";
import * as virtual from "../content/virtual";
import * as guided from "../content/guided";
import * as selfGuided from "../content/self-guided";
import * as shared from "../content/shared";

const VIRTUAL_TOUR_PAGES = [
  shared.contactInfo,
  virtual.tourInfo,
  virtual.additionalInfo,
];

const GUIDED_TOUR_PAGES = [
  shared.contactInfo,
  guided.tourInfo,
  guided.additionalInfo,
];

const SELF_TOUR_PAGES = [
  shared.contactInfo,
  selfGuided.tourInfo,
  selfGuided.additionalInfo,
];

export default function FormPagerLite({
  guidelines,
  intro,
  endDate,
  startDate,
  excludedDates,
}) {
  const [currentPage, setPage] = useState(1);
  const [tourType, setTourType] = useState(false);
  const [termsAccepted, setAccept] = useState(false);

  const pages =
    tourType === "virtual_tour"
      ? VIRTUAL_TOUR_PAGES
      : tourType === "guided_tour"
      ? GUIDED_TOUR_PAGES
      : SELF_TOUR_PAGES;
  const typeLabel =
    tourType === "virtual_tour"
      ? "Virtual Tour"
      : tourType === "guided_tour"
      ? "Guided Tour (In-Person)"
      : "Teacher-Led Tour";
  const totalPages = 2;

  const sectionElement = document.getElementById("SchoolTourForms").parentNode;

  const handlePrev = (e) => {
    e.preventDefault();
    sectionElement.scrollIntoView();
    return currentPage !== 1 ? setPage(currentPage - 1) : null;
  };
  const handleNext = (e) => {
    e.preventDefault();
    sectionElement.scrollIntoView();
    return currentPage !== totalPages ? setPage(currentPage + 1) : null;
  };

  const handleAccept = () => setAccept((prevVal) => !prevVal);

  return (
    <>
      <FormSelectCard
        className={currentPage !== 1 ? "m-form__card--hidden" : undefined}
        handleTourType={setTourType}
        intro={intro}
      />
      {pages.map((page, i) => (
        <FieldsCard
          key={page.label}
          className={currentPage !== 2 ? "m-form__card--hidden" : undefined}
          typeLabel={typeLabel}
          endDate={endDate}
          startDate={startDate}
          excludedDates={excludedDates}
          {...page}
        />
      ))}
      <SubmitCard
        className={currentPage !== 2 ? "m-form__card--hidden" : undefined}
        handleAccept={handleAccept}
        tourType={tourType}
        guidelines={guidelines}
      />
      <div className="m-form__buttons">
        {currentPage === 1 ? (
          <button
            onClick={handleNext}
            disabled={!tourType}
            className="m-form__nav-button"
          >
            Next
            <i className="icon-arrow-right"></i>
          </button>
        ) : (
          <>
            <button onClick={handlePrev} className="m-form__nav-button">
              <i className="icon-arrow-left"></i>
              Previous
            </button>
            <button
              type="submit"
              disabled={!termsAccepted}
              className="m-form__submit-button"
            >
              Submit
            </button>
          </>
        )}
      </div>
    </>
  );
}
