import Flickity from "flickity";

export default class SlideNav {
  constructor(el, props, refs) {
    const itemArray = Array.from(refs.items.querySelectorAll("li"));

    if (itemArray.length <= 5) return;

    const activeItem = itemArray.findIndex(
      (item) => item.dataset.ref === "activeItem"
    );

    const slider = new Flickity(refs.items, {
      freeScroll: true,
      contain: true,
      initialIndex: activeItem,
      pageDots: false,
      cellAlign: "left",
    });
  }
}
