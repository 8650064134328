import classNames from "classnames";
import Sb from "../abstract/StatefulBehavior";

export default class RevealNav extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      search: false,
      hamburger: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.bindTargets();
    this.searchTimeout = null;
  }

  get unlockedStyles() {
    return {
      position: `static`,
      overflow: `visible`,
    };
  }

  get lockedStyles() {
    return {
      position: `absolute`,
      top: 0,
      width: `100%`,
      overflow: `hidden`,
    };
  }

  update = () => {
    this.el.className = classNames({
      "search-open": this.state.search,
      "menu-open": this.state.hamburger,
      "menu-closed": !this.state.search && !this.state.hamburger,
    });

    if (this.state.search) {
      this.searchTimeout = setTimeout(() => {
        this.refs.searchInput.focus();
      }, 200);
    } else {
      clearTimeout(this.searchTimeout);
    }
  };

  bindTargets() {
    const { hamburgerActivator, searchOpen, searchClose, subMenuActivator } =
      this.refs;

    if (this.refs.hamburgerActivator) {
      hamburgerActivator.addEventListener("click", () => {
        this.toggle("hamburger");
      });
    }

    if (this.refs.subMenuActivator) {
      [...subMenuActivator].forEach((link) => {
        link.addEventListener("click", () => {
          this.toggle("hamburger");
        });
      });
    }

    if (this.refs.searchOpen && this.refs.searchClose) {
      searchOpen.addEventListener("click", () => {
        this.open("search");
      });

      [...searchClose].forEach((closeButton) => {
        closeButton.addEventListener("click", () => {
          this.close("search");
        });
      });
    }
  }

  disableScroll = () => {
    Object.assign(document.body.style, this.lockedStyles);
  };

  enableScroll = () => {
    Object.assign(document.body.style, this.unlockedStyles);
  };

  toggle(reference) {
    if (this.state[reference]) {
      this.close(reference);
    } else {
      this.open(reference);
    }
  }

  open(reference) {
    this.setState({
      search: reference === "search",
      hamburger: reference === "hamburger",
    });
    this.props.scrolllock && this.disableScroll();

    const closeOnEscape = (event) => {
      if (
        event.key === "Escape" ||
        event.key === "Esc" ||
        event.keyCode === 27 ||
        event.which === 27
      ) {
        this.close(reference);
        document.removeEventListener("keyup", closeOnEscape);
      }
    };
    document.addEventListener("keyup", closeOnEscape);
  }

  close(reference) {
    this.setState({
      [reference]: false,
    });
    this.props.scrolllock && this.enableScroll();
  }
}
