import "whatwg-fetch";
import qs from "query-string";

export default class EventsAPIClient {
  fetchData(path, params, onSuccess, onError) {
    const url = params ? path + "?" + qs.stringify(params) : path;

    window
      .fetch(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          /* eslint-disable no-console */
          console.log(
            `API Request completed but status was ${response.status}`
          );
          /* eslint-enable no-console */
        } else {
          response.json().then(onSuccess);
        }
      })
      .catch((error) => {
        onError(error);
      });
  }
}
