export const contactInfo = {
  label: "Contact Information",
  columns: [
    [
      {
        type: "text",
        name: "booking_name",
        label: "Name (person booking the tour)",
        required: true,
      },
      {
        type: "email",
        name: "booking_email",
        label: "Email (person booking the tour)",
        required: true,
      },
      {
        type: "text",
        name: "booking_phone",
        label: "Preferred Phone Number (person booking the tour)",
        required: true,
      },
      {
        type: "text",
        name: "best_time",
        label: "Best time to reach you",
        required: true,
      },
      {
        type: "text",
        name: "principal_name",
        label: "Principal name",
        required: true,
      },
      {
        type: "email",
        name: "principal_email",
        label: "Principal email",
        required: true,
      },
    ],
    [
      {
        type: "text",
        name: "teacher_names",
        label:
          "Teacher(s) names attending the tour (please note who is lead teacher)",
        required: true,
      },
      {
        type: "text",
        name: "email",
        label: "Teacher(s) emails attending tour",
        required: true,
      },
      {
        type: "tel",
        name: "lead_teacher_phone",
        label: "Lead Teacher Cell Number",
        required: true,
      },
      {
        type: "text",
        name: "school_name",
        label: "School/Organization Name",
        required: true,
      },
      {
        type: "text",
        name: "school_address",
        label: "School Address",
        required: true,
      },
      {
        type: "tel",
        name: "school_phone",
        label: "School Phone Number",
        required: true,
      },
    ],
  ],
};
