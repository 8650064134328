import PropTypes from "prop-types";

const videoType = PropTypes.shape({
  youtube_id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  video_image: PropTypes.string.isRequired,
  description: PropTypes.string,
}).isRequired;

export default videoType;
