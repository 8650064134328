import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import dateUtility from "../../../helpers/dateUtility";

export default class CalendarList extends React.Component {
  static displayName = "Calendar.List";

  static propTypes = {
    days: PropTypes.array.isRequired,
  };

  componentDidMount() {
    if (window && window.addeventatc) window.addeventatc.refresh();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only update when the day data changes (after API request)
    return this.props.days !== nextProps.days;
  }

  componentDidUpdate(prevProps, prevState) {
    if (window && window.addeventatc) window.addeventatc.refresh();
  }

  getSubtypeLabel(subtype) {
    switch (subtype) {
      case "virtual":
        return "Virtual";
      case "video_premiere":
        return "Video Premiere";
      default:
        return "";
    }
  }

  renderCalendarButton(item, className = "") {
    const { name, teaser, start, end } = item;

    if (!start?.date) return null;

    return (
      <p className={`add-button-wrapper ${className}`}>
        <button title="Add to Calendar" className="addeventatc">
          <span className="addeventatc__button-text">Add to Calendar</span>
          <span className="start">{start.date}</span>
          <span className="end">{end.date}</span>
          <span className="timezone">{start.timezone}</span>
          <span className="title">{name}</span>
          {teaser && <span className="description">{teaser}</span>}
          <span className="location">Norton Museum of Art</span>
        </button>
      </p>
    );
  }

  renderItemBody(item) {
    return (
      <div>
        <span className="label">
          <span className="time">{item.time}</span>
          {item.subtype && (
            <span className="subtype">
              {this.getSubtypeLabel(item.subtype)}
            </span>
          )}
          {item.type && <span className="category">{item.type}</span>}
        </span>
        <h4 className="title">{item.name}</h4>
        {item.teaser ? (
          <div
            className="content-default"
            dangerouslySetInnerHTML={{ __html: item.teaser }}
          />
        ) : null}
        {item.image ? (
          <figure>
            <img src={item.image} alt="" />
          </figure>
        ) : null}
        <div className="actions">
          {!item.do_not_link && item.url && (
            <a href={item.url} className="button-secondary">
              {"See Details"}
            </a>
          )}
          {this.renderCalendarButton(item, "mobile")}
        </div>
      </div>
    );
  }

  renderItem(item, index, ongoingItems = null) {
    return (
      <React.Fragment key={index}>
        <li>
          <span className="time">
            <p>{item.time}</p>
            {this.renderCalendarButton(item)}
          </span>
          <div className="body">{this.renderItemBody(item)}</div>
        </li>

        {item.meta === "pin-ongoing" && ongoingItems.length > 0
          ? ongoingItems.map((ongoingItem, ongoingIndex) =>
              this.renderItem(ongoingItem, index + "." + ongoingIndex)
            )
          : null}
      </React.Fragment>
    );
  }

  renderItems(items, ongoingItems = null) {
    return (
      <ul className="chronological-list-primary">
        {items.map((item, index) => {
          return this.renderItem(item, index, ongoingItems);
        })}
      </ul>
    );
  }

  renderDayHeader(date) {
    const { weekday, day } = this.getHeaderData(date);

    return this.renderMultiHeader(weekday, day);
  }

  renderMultiHeader(weekday, day) {
    return (
      <header className="day-header">
        <h2>
          <span className="weekday-multi">{weekday}</span>
          <span className="main-day-header">{day}</span>
        </h2>
      </header>
    );
  }

  renderSingleHeader(weekday, day) {
    return (
      <header className="day-header">
        <h2>
          <span className="main-day-header">{weekday}</span>
          <span className="date">{day}</span>
        </h2>
      </header>
    );
  }

  getHeaderData(dateString) {
    const date = dateUtility.stringToDate(dateString);
    const isToday = new Date().toDateString() === date.toDateString();
    const weekday = isToday ? "Today" : format(date, "EEEE");
    return {
      weekday,
      day: dateUtility.dayFormat(null, date),
    };
  }

  render() {
    const days = this.props.days;
    if (!days) return false;

    return (
      <div>
        <ul className="days">
          {days.length === 0 && (
            <li className="day">
              <header className="day-header">
                <h2>
                  <span className="weekday-multi">
                    There are no events that match your selection.
                  </span>
                </h2>
                <p className="content-default">
                  Please try selecting one or more additional categories,
                  clearing your categories, or selecting a day.
                </p>
              </header>
            </li>
          )}
          {days.map((day, index) => {
            return (
              <li key={index} className="day">
                {this.renderDayHeader(day.date)}
                {day.hours.open === false ? (
                  <div className="note">
                    {"The museum is closed on this day"}
                  </div>
                ) : null}
                {/* Render day header if index > 0 */}
                {day.items.length > 0
                  ? this.renderItems(day.items, day.ongoingItems)
                  : null}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
