import React from "react";

export default function SuccessCard() {
  return (
    <div className="m-form__card--not-fieldset content-default">
      <h1>Success!</h1>
      <p>
        Thank you for your interest in a school tour. We have received your
        information and will be in touch shortly.
      </p>
    </div>
  );
}
