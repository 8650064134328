import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Item from "../MiniCalendar/Item";

export default class CalendarTabContent extends PureComponent {
  static displayName = "Content.ContentTabs.CalendarContent";

  static propTypes = {
    tab: PropTypes.object,
    addClass: PropTypes.string,
  };

  renderDay(items) {
    return (
      <table className="key-value-table">
        <tbody>
          {items.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { tab, addClass } = this.props;
    const baseClass = "level";
    const levelClass = addClass ? baseClass + ` ${addClass}` : baseClass;

    return <div className={levelClass}>{this.renderDay(tab.body)}</div>;
  }
}
