const initialState = {
  open: false,
  current: null,
  imageOffsets: [],
};

export default function slideshow(state = initialState, action) {
  if (!action && !action.type) return state; // noop if the action isn't valid.

  switch (action.type) {
    case "REGISTER_ELEMENT_OFFSET":
      return Object.assign({}, state, {
        imageOffsets: state.imageOffsets.concat(action.imageOffsets),
      });

    case "OPEN_SLIDESHOW":
      return Object.assign({}, state, { open: true, current: action.current });

    case "CLOSE_SLIDESHOW":
      return Object.assign({}, state, { open: false });
  }

  return initialState;
}
