import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import ContentTabs from "../ContentTabs/Wrapper";
import Item from "./Item";

export default class MiniCalendarContent extends React.Component {
  static displayName = "MiniCalendar.Content";

  static propTypes = {
    days: PropTypes.array.isRequired,
    addClass: PropTypes.string,
    handleHeight: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.content = React.createRef();
    this.bindResize();
  }

  componentDidMount() {
    this.setHeight();
  }

  get closedEvent() {
    return {
      time: "ALL DAY",
      name: "Museum Closed",
    };
  }

  setHeight = () => {
    if (this.content.current) {
      const tabHeight = this.content.current.getBoundingClientRect().height;
      this.props.handleHeight(tabHeight);
    }
  };

  bindResize() {
    this.debouncedHeight = debounce(this.setHeight, 200);
    window.addEventListener("resize", this.debouncedHeight);
  }

  renderDay(day) {
    return (
      <table className="key-value-table">
        <tbody>
          {!day.isOpen && <Item item={this.closedEvent} />}
          {day.events.map((event, index) => (
            <Item key={index} item={event} />
          ))}
        </tbody>
      </table>
    );
  }

  renderMultiDayWrap(days) {
    let formattedDays = [];

    days.forEach((day) => {
      const { label: tabName, events } = day;
      const body = [];
      if (!day.isOpen)
        body.push(
          Object.assign({}, { time: "All Day", name: "Museum Closed" })
        );
      body.push(...events);
      const formattedDay = Object.assign({}, { tabName, body });
      formattedDays.push(formattedDay);
    });

    formattedDays = JSON.stringify(formattedDays).replace(
      /tabName/g,
      "tab_name"
    );
    formattedDays = JSON.parse(formattedDays);
    return (
      <ContentTabs
        tabs={formattedDays}
        content_type="calendar"
        className="calendar-tabs"
        showOverflow
      />
    );
  }

  renderDays(days, multiday = false) {
    return multiday ? this.renderMultiDayWrap(days) : this.renderDay(days[0]);
  }

  render() {
    const { days, addClass } = this.props;
    if (!days) return false;

    const multiday = days.length > 1;
    const classes = addClass ? `level ${addClass}` : "level";

    return (
      <div className={classes} ref={this.content}>
        {this.renderDays(days, multiday)}
      </div>
    );
  }
}
