import Headroom from "headroom.js";

export default class ReduceHeader {
  constructor(el) {
    const headroom = new Headroom(el, {
      offset: 100,
    });
    headroom.init();
  }
}
