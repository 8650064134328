import "whatwg-fetch";
import qs from "query-string";

function baseUrl() {
  const location = window.location;
  return `${location.protocol}//${location.hostname}`;
}

function getUrl(path, params) {
  const url = `${baseUrl()}/api/${path}/`;
  if (!params) return url;

  return url + "?" + qs.stringify(params);
}

export default function apiBase({ path, headers, params, onSuccess, onError }) {
  return window
    .fetch(getUrl(path, params), headers || {})
    .then((response) => {
      if (response.status !== 200) {
        // eslint-disable-next-line
        console.log(`API Request completed but status was ${response.status}`);
      } else {
        response.json().then(onSuccess);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}
