import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class CalendarFilters extends PureComponent {
  static displayName = "Calendar.Filters";

  static propTypes = {
    items: PropTypes.array.isRequired,
    selected: PropTypes.array,
    onFilterClick: PropTypes.func,
    onFilterClear: PropTypes.func,
    visibility: PropTypes.bool,
    getHeight: PropTypes.func,
  };

  refCallback = (element) => {
    this.props.getHeight(element.getBoundingClientRect().height);
  };

  render() {
    const { items, selected, onFilterClick, onFilterClear, visibility } =
      this.props;
    const containerClasses = classNames("filter-list", {
      visible: visibility,
      hidden: !visibility,
    });

    if (!items) return false;

    return (
      <div ref={this.refCallback} className={containerClasses}>
        <header className="filter-list__header">
          <h4>Select a Category</h4>
          <button onClick={onFilterClear} className="filter-list__clear">
            Clear
          </button>
        </header>
        <ul>
          {items.map((item, index) => {
            return (
              <li key={item.id}>
                <button
                  className={classNames([
                    "filter-list__filter",
                    { active: selected.indexOf(item.id) > -1 },
                  ])}
                  onClick={() => {
                    onFilterClick(item.id);
                  }}
                >
                  {item.title}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
